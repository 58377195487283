/*
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
*/
const DashboardPage = () => import('@/views/dashboard/DashboardPage');
const MyDashboardPage = () => import('@/views/dashboard/MyDashboardPage');
const MyTasksPage = () => import('@/views/dashboard/MyTasksPage');
const ActivityPage = () => import('@/views/dashboard/ActivityPage');

const meta = {
  layout: 'DefaultLayout',
  hasSidebar: true,
  shouldRemountRouter: false,
  layoutLoader: true,
};

const dashboard = [
  {
    path: '/dashboard',
    name: 'dashboard',
    component: DashboardPage,
    redirect: '/dashboard/my-dashboard',
    children: [
      {
        path: '/dashboard/my-dashboard',
        name: 'my-dashboard',
        component: MyDashboardPage,
        meta,
      },
      {
        path: '/dashboard/my-tasks',
        name: 'my-tasks',
        component: MyTasksPage,
        meta: {
          ...meta,
          feature: 'dash_tasks',
        },
      },
      {
        path: '/dashboard/activity',
        name: 'activity',
        component: ActivityPage,
        meta,
      },
    ],
  },
];

export default dashboard;
