import { render, staticRenderFns } from "./AwsIcon.vue?vue&type=template&id=9033a5a8&scoped=true"
import script from "./AwsIcon.vue?vue&type=script&lang=js"
export * from "./AwsIcon.vue?vue&type=script&lang=js"
import style0 from "./AwsIcon.vue?vue&type=style&index=0&id=9033a5a8&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../temp_divo-ui/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9033a5a8",
  null
  
)

export default component.exports