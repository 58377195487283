<template>
  <default-description-tooltip
    data-closable="selectReplyer"
    :items="props.items"
    list-position="left"
    :selected-item="selected"
    :has-triangle="false"
    :is-right-overflow="false"
    :max-height="maxHeight"
    @choose="emit('select', $event)"
  >
    <template #default>
      <div
        class="sms-number-select flex-row-between flex-gap-4 align-item-center"
      >
        <div class="sms-number-select__number flex-row-between flex-gap-4 cursor-pointer align-item-center">
          <div
            v-if="props.selected?.name"
            class="flex-row flex-gap-8 flex-justify-content-between"
          >
            <p
              class="text_xs"
            >
              {{ formatPhoneNumber(props.selected?.name) }}
            </p>
          </div>
          <p
            v-else
            class="text_xs"
          >
            {{ t('inboxPage.chatSection.notifications.numberNotSelected') }}
          </p>
          <i class="bx bx-chevron-down icon_size_16" />
        </div>
        <p
          class="text_xs color_tertiary"
        >
          {{ props.selected?.rightData || '' }}
        </p>
      </div>
    </template>
  </default-description-tooltip>
</template>
<script setup>
  import { formatPhoneNumber } from '@/utils/dialer/utils';
  import DefaultDescriptionTooltip from '@/components/base/uiKit/DefaultDescriptionTooltip.vue';
  import { useI18n } from '@/i18n';

  const { t } = useI18n();

  const emit = defineEmits(['select']);
  const props = defineProps({
    selected: {
      type: Object,
      default: null,
    },
    items: {
      type: Array,
      required: true,
    },

    maxHeight: {
      type: String,
      default: '',
    },
  });
</script>
<style lang="scss">
.sms-number-select {
 &__number {
  background: var(--neutral-50);
   border-radius: 12px;
   padding: 4px 8px;
 }
}
</style>
