const state = () => ({
  user: null,
  isGHLConnected: false,

  _disabledBrandSettings: {
    isLinkTracking: ['tendex'],
  },
  brandSettings: {
    isLinkTracking: true,
  },
});

const getters = {
  user: state => {
    return state.user;
  },
  isGHLConnected: state => {
    return state.isGHLConnected;
  },
  socialName: state => {
    if (['buzz', 'kennected'].includes(state.user?.brand)) {
      return 'Social';
    }
    return 'LinkedIn';
  },
  isBuzzBrand: state => {
    return state.user?.brand === 'buzz';
  },
  isKennectedBrand: state => {
    return state.user?.brand === 'kennected';
  },
  brandSettings: state => {
    return state.brandSettings;
  },
};

const mutations = {
  SET_USER (state, payload) {
    state.user = payload;
    state.isGHLConnected = payload.config.is_ghl_connected;
  },
  SET_BRAND_SETTINGS (state) {
    const brand = state.user.brand;
    Object.entries(state._disabledBrandSettings).forEach(([setting, brands]) => {
      if (state.brandSettings[setting] && brands.includes(brand)) {
        state.brandSettings[setting] = false;
      }
    });
  },
};

const actions = {
  SET_USER ({ commit }, payload) {
    commit('SET_USER', payload);
    commit('SET_BRAND_SETTINGS');
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
