<template>
  <section>
    <header>
      <h1 class="text_xl">
        {{ $t('modals.recentPrompts.title') }}
      </h1>
    </header>

    <main class="mt-3">
      <ul
        ref="promptsListRef"
        class="recent-prompts__list"
      >
        <template v-if="prompts?.length">
          <default-list-item
            v-for="prompt in prompts"
            :key="prompt._id"
            class="recent-prompt"
            :title="prompt.goal"
            left-icon-name="bx bxs-magic-wand"
            left-icon-classes="color_tertiary icon_size_20"
            right-icon-classes="bx bx-chevron-right color_tertiary"
            size="s"
            @click.native.stop="emit('select', prompt)"
          >
            <template #text>
              <p class="text_ellipsis color_tertiary">
                {{ prompt.ai_response }}
              </p>
            </template>
          </default-list-item>
        </template>

        <p
          v-else
          class="text_s text_center color_tertiary"
        >
          {{ $t('modals.recentPrompts.emptyState.recentPromptsList') }}
        </p>
      </ul>
    </main>

    <footer
      v-if="prompts?.length"
      class="mt-3"
    >
      <default-button
        :title="$t('actions.clearHistory')"
        color="danger"
        @action="emit('clear-recent-prompts')"
      />
    </footer>
  </section>
</template>

<script setup>
  import { ref } from 'vue';
  import DefaultListItem from '@/components/base/uiKit/DefaultListItem.vue';
  import DefaultButton from '@/components/base/uiKit/DefaultButton.vue';
  import { useInfiniteScroll } from '@/use/useInfiniteScroll';

  const props = defineProps({
    prompts: {
      type: Array,
      default: null,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  });

  const emit = defineEmits('select', 'clear-recent-prompts', 'load-more-prompts');

  const promptsListRef = ref(null);

  // #region Infinite scroll
  useInfiniteScroll(promptsListRef, infiniteScrollCallbak, 280);

  function infiniteScrollCallbak () {
    if (props.isLoading) return;
    emit('load-more-prompts');
  }
  // #endregion
</script>

<style lang="scss">
.recent-prompts {
  &__list {
    max-height: 280px;
    overflow: auto;
  }
}
</style>
