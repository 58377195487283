import { unref, onMounted, onBeforeUnmount } from 'vue';

/**
 * @param {import('vue').Ref<HTMLElement | null | import('vue').Component> | HTMLElement} target target scroll element
 * @param {() => Promise<void>} callback callback that fires on scroll offset
 * @param {number} offset height to bottom of scrollbar
 */
export const useInfiniteScroll = (target, callback, offset = 300) => {
  let _target = target;

  onMounted(() => {
    if ('$el' in unref(target)) {
      _target = unref(target).$el;
    } else {
      _target = unref(target);
    }

    addInfiniteScrollListener();
  });

  onBeforeUnmount(() => {
    removeInfiniteScrollListener();
  });

  const addInfiniteScrollListener = () => {
    _target?.addEventListener('scroll', handleInfiniteScroll, { passive: true });
  };

  const removeInfiniteScrollListener = () => {
    _target?.removeEventListener('scroll', handleInfiniteScroll, { passive: true });
  };

  const handleInfiniteScroll = async () => {
    const triggerHeight = _target.scrollTop + _target.clientHeight + Math.max(0, offset);

    if (triggerHeight >= _target.scrollHeight) {
      await callback();
    }
  };

  return {
    addInfiniteScrollListener,
    removeInfiniteScrollListener,
  };
};
