export const formatPhoneNumber = (phoneNumberString) => {
  const cleaned = String(phoneNumberString).replace(/\D/g, '');
  if (cleaned?.length === 11) {
    const match = cleaned.match(/^(\d)(\d{3})(\d{3})(\d{4})$/);
    return `+${match[1]} (${match[2]}) ${match[3]}-${match[4]}`;
  } else if (cleaned?.length === 12) {
    const match = cleaned.match(/^(\d{2})(\d{3})(\d{3})(\d{4})$/);
    return `+${match[1]} (${match[2]}) ${match[3]}-${match[4]}`;
  } else {
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]}`;
    }
  }
  return phoneNumberString;
};
