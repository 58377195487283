export class StepCardInfo {
    title;
    icon;
    body;
    errorMessage;
    constructor(title, icon, body, errorMessage) {
        this.title = title;
        this.icon = icon;
        this.body = body || {};
        this.errorMessage = errorMessage || '';
    }
}
