<template>
  <li
    ref="approveCardRef"
    class="approve-card-message list-messages__item margin-left flex-column vld-parent"
  >
    <div class="inbox-message__body inbox-message__body_right margin-left">
      <p class="inbox-message__text">
        {{ approve.approved_text }}
      </p>
    </div>

    <menu
      v-if="approve.dash_task_status === 'new'"
      class="mt-1 flex-row flex-justify-content-end flex-gap-8 text_xs"
      :class="{ 'approve-card-message__menu_disabled': isMenuDisabled }"
    >
      <default-button
        title="Approve"
        form="ellipse"
        size="xs"
        icon-name="bx-check"
        color="success"
        :disabled="isMenuDisabled"
        @action="setTaskStatus('done')"
      />
      <default-button
        title="Cancel"
        form="ellipse"
        size="xs"
        icon-name="bx-x"
        color="danger"
        :disabled="isMenuDisabled"
        @action="setTaskStatus('canceled')"
      />
      <default-button
        title="Hold"
        form="ellipse"
        size="xs"
        icon-name="bx-pause"
        color="tertiary"
        :disabled="isMenuDisabled"
        @action="setTaskStatus('hold')"
      />
      <default-button
        title="Edit"
        form="ellipse"
        size="xs"
        icon-name="bx-edit-alt"
        color="tertiary"
        :disabled="isMenuDisabled"
        @action="editApprove"
      />
    </menu>

    <span
      v-else-if="approve.dash_task_status === 'hold'"
      class="flex-row flex-gap-4 text_xs mt-05 margin-left color_tertiary"
    >
      <i class="bx bx-time-five color_tertiary icon_size_12" />
      On hold
    </span>
  </li>
</template>

<script setup>
  import { ref } from 'vue';
  import DefaultButton from '@/components/base/uiKit/DefaultButton.vue';
  import { updateTaskStatus } from '@/api/dashboardMethods';
  import { useSnack } from '@/lib/useSnack';

  const props = defineProps({
    approve: {
      type: Object,
      default: () => ({}),
    },
    isMenuDisabled: {
      type: Boolean,
      default: false,
    },
  });

  const emit = defineEmits(['update:approve', 'edit-approve']);

  const { $snack } = useSnack();

  const approveCardRef = ref();

  const setTaskStatus = async (status) => {
    try {
      await updateTaskStatus(props.approve.dash_task_id, status);

      emit('update:approve');

      const getSnackMessage = (status) => {
        switch (status) {
        case 'done':
          return 'Message approved';
        case 'canceled':
          return 'Message canceled';
        case 'hold':
          return 'Message put on hold';
        default:
          return 'Message status succesfully changed';
        }
      };

      $snack.success(getSnackMessage(status));
    } catch (error) {
      $snack.error(error);
    }
  };

  const editApprove = () => {
    emit('edit-approve-message', props.approve.approved_text);
  };
</script>

<style lang="scss">
.approve-card-message {
  min-height: max-content;
}
</style>
