/*
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
*/

/**
 * Capitalize the first or every word of a string.
 * @param {string} string input string
 * @param {boolean} [everyWord] capitalize every word in string
 * @returns {string} capitalized input string
 */
export const capitalize = (string, everyWord = false) => {
  if (!string) return string;

  const _capitalize = (str) => str[0].toUpperCase() + str.slice(1);

  if (everyWord) {
    return string.split(' ').map((word) => _capitalize(word)).join(' ');
  }

  return _capitalize(string);
};

export const copyTextBuffer = (string) => {
  navigator.clipboard.writeText(string);
};

export const getSnakeCase = str => str.toLowerCase().replace(/\s+/ig, '_');

export const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

/**
 * Get object key value by string key
 * @param {object} object target object
 * @param {string} key key as string, e.g. `'foo.bar.buzz'`
 * @returns {unknown} target object value
 */
export const getObjectValueByStringKey = (object, key) => {
  return key.split('.').reduce((acc, key) => acc[key], object);
};

export const isObject = (item) => {
  return (typeof item === 'object' && !Array.isArray(item) && item !== null);
};
