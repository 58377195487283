import { getSignature } from '@/api/credentialsMethods';

export default {
  namespaced: true,
  state: {
    signaturesList: null,
  },
  getters: {
    signaturesList (state) {
      return state.signaturesList;
    },
  },
  mutations: {
    SET_SIGNATURES_LIST (state, payload) {
      state.signaturesList = payload;
    },
    UPDATE_SIGNATURES_LIST (state, payload) {
      let signaturesList = state.signaturesList?.slice(0) || [];
      if (!signaturesList.length) {
        state.signaturesList.push(payload);
      }

      if (payload.is_default) {
        signaturesList = signaturesList.map(signature => {
          signature.is_default = false;
          return signature;
        });
      }
      const existingIndex = signaturesList.findIndex(signature => signature._id === payload._id);

      if (existingIndex > -1) {
        signaturesList.splice(existingIndex, 1, payload);
      } else {
        signaturesList.push(payload);
      }
      state.signaturesList = signaturesList;
    },
    ADD_SIGNATURE (state, payload) {
      let signaturesList = state.signaturesList?.slice(0) || [];

      if (payload.is_default) {
        signaturesList = signaturesList.map(signature => {
          signature.is_default = false;
          return signature;
        });
      }

      signaturesList.push(payload);

      state.signaturesList = signaturesList;
    },
    DELETE_SIGNATURE (state, payload) {
      const signaturesList = state.signaturesList?.slice(0) || [];
      state.signaturesList = signaturesList.filter(signature => signature._id !== payload._id);
    },
  },
  actions: {
    async SET_SIGNATURES_LIST ({ commit }, credentialId) {
      const signatures = await getSignature(credentialId);
      commit('SET_SIGNATURES_LIST', signatures);
    },
  },
};
