/*
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
*/
// const PricingPage = () => import('@/views/PricingPage');
const UnpaidPage = () => import('@/views/UnpaidPage');

const meta = {
  layout: 'DefaultLayout',
  hasSidebar: true,
};

const pricing = [
  // {
  //   path: '/pricing',
  //   name: 'pricing',
  //   component: PricingPage,
  //   meta,
  //   children: [
  //     {
  //       path: '/pricing/extension/:priceId',
  //       name: 'extension',
  //       component: PricingPage,
  //       meta,
  //     },
  //     {
  //       path: '/pricing/add-seat',
  //       name: 'add-seat',
  //       component: PricingPage,
  //       meta,
  //     },
  //   ],
  // },
  {
    path: '/billing-warning',
    name: 'billing-warning',
    component: UnpaidPage,
    meta,
  },
];

export default pricing;
