/*
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
*/
import { useHttp } from '@/middleware/httpMiddleware';

/**
 * @param {String} credId
 * @param {Object.<string, { decision: 'merge' | 'create' | 'undo', data: [] }>} resolveRequests
 * @param {String} [platform]
 */
async function closeResolveRequests (credId, resolveRequests, platform = 'hubspot') {
  const data = await useHttp(
    `/integrations/crm/${platform}/resolve_requests`,
    'POST',
    {
      resolves: {
        ...resolveRequests,
      },
    },
    {
      credentials_id: credId,
    },
    true
  );

  return data.payload;
}

/**
 * @param {String} credId
 * @param {String} crmModelType
 * @param {Number} [offset]
 * @param {Number} [limit]
 * @param {String} [platform]
 */
async function fetchResolveRequests (credId, crmModelType, offset = 0, limit = 25, platform = 'hubspot') {
  const data = await useHttp(
    `/integrations/crm/${platform}/resolve_requests`,
    'GET',
    null,
    {
      credentials_id: credId,
      crm_model_type: crmModelType,
      offset,
      limit,
    }
  );

  return data.payload;
}

/**
 * @param {String} credId
 * @param {String} requestId
 * @param {Number} [offset]
 * @param {Number} [limit]
 * @param {String} [platform]
 */
async function fetchResolveRequestsById (credId, requestId, offset = 0, limit = 10, platform = 'hubspot') {
  const data = await useHttp(
    `/integrations/crm/${platform}/resolve_requests/${requestId}`,
    'GET',
    null,
    {
      credentials_id: credId,
      offset,
      limit,
    }
  );

  return data.payload;
}

export {
  closeResolveRequests,
  fetchResolveRequests,
  fetchResolveRequestsById
};
