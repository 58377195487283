<!--
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
-->
<template>
  <default-tooltip
    :items="props.items"
    :list-position="props.listPosition"
    :icon-position="props.iconPosition"
    :has-triangle="props.hasTriangle"
    :is-fulled="props.isFulled"
    :is-disabled="props.isDisabled"
    :max-height="props.maxHeight"
    :list-width="props.listWidth"
    :is-closable="props.isClosable"
    :data-closable="props.dataClosable"
    :empty-state="props.emptyState"
    @update:show="showTooltip = $event"
    @choose="emit('choose', $event)"
  >
    <template #rightSlot="{ data }">
      <span
        class="text_xs color_tertiary margin-left text_ellipsis"
        :class="{ 'text_ellipsis_40': isRightOverflow }"
      >
        {{ data.rightData }} <i
          v-if="selectedItem?.name === data.name"
          class="bx color_success bx-check"
        />
      </span>
    </template>
    <template #default>
      <slot name="default" />
    </template>
  </default-tooltip>
</template>
<script setup>
  import { ref, watch } from 'vue';
  import DefaultTooltip from '@/components/base/uiKit/DefaultTooltip.vue';
  const emit = defineEmits(['update:show', 'choose']);

  const props = defineProps({
    items: {
      type: Array,
      default: () => [], // required нельзя ставить, иначе будет падать ошибка в консоль у зависимого компонента - DefaultTooltipBlock
    },
    selectedItem: {
      type: Object,
      default: () => ({}),
    },
    listPosition: {
      type: String,
      default: 'left', // left, center, right
    },
    iconPosition: {
      type: String,
      default: '', // right
    },
    hasTriangle: {
      type: Boolean,
      default: true,
    },
    isFulled: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    maxHeight: {
      type: String,
      default: 'auto',
    },
    listWidth: {
      type: String,
      default: '',
    },
    isClosable: {
      type: Boolean,
      default: true,
    },
    show: {
      type: Boolean,
      default: false,
    },
    dataClosable: {
      type: String,
      default: '',
    },
    emptyState: {
      type: String,
      default: 'There are no items',
    },
    isRightOverflow: {
      type: Boolean,
      default: true,
    },
  });
  const showTooltip = ref(props.show);

  watch(showTooltip, () => {
    emit('update:show', showTooltip.value);
  });
</script>
