<!--
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
-->
<template>
  <div
    class="crm-table-column"
    @mousedown.stop="grabPage"
    @mouseup.stop="unGrabPage"
    @mousemove.stop="dragPage"
  >
    <validation-observer
      v-if="isAddStage"
      v-slot="{ passes }"
    >
      <form @submit.prevent="passes(saveStage)">
        <div class="crm-table-column__add-stage-active">
          <div class="crm-table-column__item-header" />
          <default-input
            size="s"
            :value.sync="stageName"
            validation-string="required"
            :placeholder="$t('pipelinesPage.modals.addStage.inputs.stageName.placeholder')"
          />

          <div class="crm-table__flex">
            <default-input
              size="s"
              :value.sync="probability"
              validation-string="required|integer|between:0,100"
              :placeholder="$t('pipelinesPage.modals.addStage.inputs.probability.placeholder')"
            />
            <default-button
              size="m"
              color="danger"
              form="circle"
              icon-name="bx-x"
              @action="isAddStage = false"
            />
            <default-button
              size="m"
              color="success"
              type="submit"
              form="circle"
              icon-name="bx-check"
            />
          </div>
        </div>
      </form>
    </validation-observer>
    <div
      v-else
      class="crm-table-column__item"
    >
      <div class="crm-table-column__item-header" />
      <div class="crm-table-column__info">
        <span
          class="crm-table-column__text text_s crm-table-column__text_hidden"
        >{{ stage.name }}</span>
        <div class="crm-table-column__info-flex">
          <span class="crm-table-column__text text_xs color_secondary">{{ stage.total_crm_deals || 0 }}
            {{ "deal" + ( stage.total_crm_deals > 1 ? "s" : "") }}</span>
          <span class="crm-table-column__dot" />
          <span class="crm-table-column__text text_xs color_secondary">{{
            stage.probability + "% probability"
          }}</span>
        </div>
      </div>
      <default-tooltip
        v-if="$route.params.integration === 'default'"
        class="crm-table-column__tooltip"
        data-closable="stageMenu"
        :has-triangle="false"
        :show.sync="isShowTooltip"
        :size="'s'"
        :items="menuFields"
        @choose="selectMenuItem"
      >
        <template #default>
          <i
            class="bx bx-dots-horizontal-rounded color_secondary crm-table-column__item-icon"
          />
        </template>
      </default-tooltip>
    </div>
    <draggable
      v-model="stageDeals"
      :animation="200"
      ghost-class="ghost-card"
      group="guests"
      tag="ul"
      class="crm-table-column__deal"
      :disabled="!loaded"
      draggable=".crm-table-column__deal-card"
      @change="changeOrder($event, index)"
    >
      <template v-for="deal in stageDeals">
        <crm-deal-card
          v-if="!deal.deleted_at"
          :key="deal.name"
          class="crm-table-column__deal-card"
          :deal="deal"
          @open-modal-deal="openCrmDealModal(deal)"
        />
      </template>
      <div
        v-show="!loaded"
        ref="vldParent"
        class="vld-parent"
      />
      <div
        class="crm-table-column__add-deal"
        @click.stop="openCrmDealModal(null)"
      >
        <span class="crm-table-column__text text_xs">
          <i class="bx bx-plus" />
          {{ $t('pipelinesPage.table.column.actions.addDeal') }}
        </span>
      </div>
    </draggable>
  </div>
</template>

<script>
  import DefaultTooltip from '@/components/base/uiKit/DefaultTooltip';
  import CrmDealCard from '@/components/CRMIntegration/CrmDealCard.vue';
  import DefaultInput from '@/components/base/uiKit/DefaultInput';
  import DefaultButton from '@/components/base/uiKit/DefaultButton';
  import { ValidationObserver } from 'vee-validate';
  import { loaderMixin } from '@/mixins/loaderMixin';
  import draggable from 'vuedraggable';

  export default {
    name: 'CrmTableColumn',
    components: {
      DefaultTooltip,
      CrmDealCard,
      draggable,
      DefaultButton,
      DefaultInput,
      ValidationObserver,
    },
    mixins: [loaderMixin],
    props: {
      stage: {
        type: Object,
        default: () => {},
      },
      selectedPipeline: {
        type: Object,
        default: () => {},
      },
      index: {
        type: Number,
        default: 1,
      },
      // loaded: {
      //   type: Boolean,
      //   default: true,
      // },
      deals: {
        type: Array,
        default: () => [],
      },
    },
    data () {
      return {
        isAddStage: false,
        stageName: '',
        probability: '',
        isShowTooltip: false,
        scrollCache: 0,
        menuFields: [
          {
            name: this.$t('actions.edit'),
            iconName: 'bx-edit-alt',
            _id: 'edit',
          },
          {
            name: this.$t('actions.delete'),
            iconName: 'bx-trash',
            _id: 'delete',
          },
        ],
      };
    },
    computed: {
      stageDeals: {
        get () {
          return this.deals;
        },
        set () {
          this.$emit('update:deals', this.stageDeals);
        },
      },

    },

    mounted () {
      this.addScrollEvent();

      this.openDealModalFromQuery();
    },

    destroyed () {
      this.removeScrollEvent();
    },

    methods: {
      // Вынести в util или composable
      openDealModalFromQuery () {
        const queryStageId = this.$route.query.stage;
        if (!queryStageId || queryStageId !== this.stage._id) return;

        const queryDealId = this.$route.query.deal;
        if (!queryDealId) return;

        const targetDeal = this.stageDeals.find((deal) => {
          return deal._id === queryDealId;
        });

        if (!targetDeal) {
          this.$noty.error('Deal not found');
          return;
        }

        this.openCrmDealModal(targetDeal);
      },
      addScrollEvent () {
        this.$el.querySelector('.crm-table-column__deal').addEventListener('scroll', this.loadDeals);
      },
      removeScrollEvent () {
        this.$el.querySelector('.crm-table-column__deal').removeEventListener('scroll', this.loadDeals);
      },
      loadDeals () {
        this.loaded = !this.stage.loading;

        this.$emit('load-deals', {
          stageId: this.stage._id,
          offset: this.deals.length,
        });
        this.loaded = true;
      },
      grabPage (e) {
        this.$emit('grab-page', e);
      },
      dragPage (e) {
        this.$emit('drag-page', e);
      },
      unGrabPage () {
        this.$emit('un-grab-page');
      },
      changeOrder (deal, i) {
        this.$emit('un-grab-page');
        this.$emit('change-order', deal, i);
      },
      openCrmDealModal (deal) {
        this.$emit('open-crm-deal-modal', this.stage, deal);
      },
      saveStage () {
        if (
          Number(this.probability) >= 0 &&
          Number.isInteger(Number(this.probability))
        ) {
          this.$emit(
            'save-stage',
            this.stageName,
            this.probability,
            this.selectedPipeline.external_id,
            this.stage._id
          );
          this.stageName = '';
          this.probability = '';
          this.isAddStage = false;
        } else {
          this.$noty.error(
            this.$t('pipelinesPage.notifications.probabilityMustBePositive')
          );
        }
      },
      selectMenuItem (item) {
        if (this.stage && item._id === 'edit') {
          this.stageName = this.stage.name;
          this.probability = this.stage.probability;
          this.isAddStage = !this.isAddStage;
        }
        if (item._id === 'delete') {
          this.$emit('delete-stage', this.stage);
        }
      },
    },
  };
</script>

<style lang="scss">
.crm-table-column {
  &__deal-card {
    width: 188px;
  }

  display: flex;
  flex-flow: column;
  gap: 8px;
  height: calc(100vh - 76px - 151px);
  &__item {
    padding: 8px;
    display: flex;
    width: 188px;
    position: relative;
    background: var(--table-bg);
    border-radius: 4px;
    margin-bottom: 8px;
    &-header {
      background: var(--primary-cl);
      height: 4px;
      border-radius: 4px 4px 0px 0px;
      top: 0;
      width: 188px;
      left: 0;
      position: absolute;
    }
    &-icon {
      cursor: pointer;
      font-size: 24px;
      display: flex;
      margin-left: auto;
      align-items: center;
      &:hover {
        color: var(--primary-cl);
      }
    }
  }
  &__info {
    display: block;
    &-flex {
      display: flex;
      align-items: center;
      gap: 4px;
    }
  }
  &__deal {
    display: flex;
    flex-flow: column;
    gap: 8px;
    min-height: 100%;
    transition: 0.3s;
    width: 100%;
    overflow: scroll;
    &:hover {
      .crm-table-column__add-deal {
        display: flex;
      }
    }
    // overflow-y: auto;
    // height: calc(100vh - 76px - 62px - 40px);
  }
  &__add-deal,
  &__add-stage {
    min-height: 100px;
    width: 100%;
    border: 1px dashed var(--secondary-text-color);
    display: flex;
    align-items: center;
    border-radius: 8px;
    justify-content: center;
    cursor: pointer;
    color: var(--primary-cl);
    transition: 0.3s;
    &:hover {
      opacity: 0.7;
    }
  }
  &__add-deal {
    display: none;
  }
  &__add-stage {
    height: 48px;
    min-height: 48px;
    flex-shrink: 0;
  }
  &__add-stage-active {
    padding: 8px;
    display: flex;
    min-width: 188px;
    max-width: 188px;
    position: relative;
    background: var(--table-bg);
    border-radius: 4px;
    margin-bottom: 8px;
    height: min-content;
    flex-flow: column;
    gap: 4px;
    &-header {
      background: var(--primary-cl);
      height: 4px;
      border-radius: 4px 4px 0px 0px;
      top: 0;
      width: 188px;
      left: 0;
      position: absolute;
    }
  }
  &__flex {
    display: flex;
    align-items: center;
    gap: 4px;
  }
  &__dot {
    background: var(--secondary-text-color);
    width: 4px;
    height: 4px;
    border-radius: 50%;
    display: flex;
    align-items: center;
  }
  &__tooltip {
    display: flex;
    align-items: center;
    margin-left: auto;
  }
  &__text {
    display: flex;
    align-items: center;
    gap: 4px;
    &_hidden {
      display: block;
      max-width: 90%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #fff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
</style>
