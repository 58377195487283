function setConvertBox (uuid) {
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.src = 'https://cdn.convertbox.com/convertbox/js/embed.js';
  script.id = 'app-convertbox-script';
  script.async = true;
  script.dataset.uuid = uuid;

  const head = document.getElementsByTagName('head')[0];
  head.appendChild(script);
}
export { setConvertBox };
