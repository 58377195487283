<!--
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
-->
<template>
  <li
    class="switch-block"
    @click="change"
  >
    <div class="switch-block__header">
      <h3 class="text_s switch-block__title">
        {{ title }}
      </h3>
      <div
        class="switch-block__switch"
        @click.stop
      >
        <default-switcher
          :is-checked="isChecked"
          size="s"
          @change="change"
        />
      </div>
    </div>
    <p
      v-if="!!description"
      class="text_xs switch-block__description"
    >
      {{ description }}
    </p>
    <div class="flex margin-right mt-1 flex-justify-content-start">
      <default-label
        v-if="labelInfo"
        size="xs"
        :label="labelInfo"
      />
    </div>
  </li>
</template>

<script>
  import DefaultSwitcher from '@/components/base/uiKit/DefaultSwitcher.vue';
  import DefaultLabel from '@/components/base/uiKit/DefaultLabel.vue';

  export default {
    name: 'SettingsSwitchBlock',
    components: {
      DefaultLabel,
      DefaultSwitcher,
    },
    props: {
      title: {
        type: String,
        default: '',
      },
      description: {
        type: String,
        default: '',
      },
      isChecked: {
        type: Boolean,
        default: false,
      },
      labelInfo: {
        type: Object,
        default: null,
      },
    },

    methods: {
      change () {
        this.$emit('change');
      },
    },
  };
</script>

<style lang="scss">
.switch-block {
  cursor: pointer;
  list-style: none;
  padding: 16px;
  border-radius: 8px;
  border: $border;
  width: 100%;
  min-width: 280px;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    gap: 4px;
  }

  &__title {
    font-weight: 400;
  }

  &__description {
    width: 90%;
    line-height: 12px;
    margin-top: 4px;
    color: #919399;
  }
}
</style>
