import { ref, computed, reactive } from 'vue';
import { useStore } from '@/store';
import { useCredentials } from '@/use/credentials/useCredentials';
// #endregion
// #region Store composable
const store = useStore();
const selectedCredential = computed(() => {
    return store.getters['credentials/selectedCredential'];
});
// #endregion
// #region Credentials composable
const { switchCredential } = useCredentials();
// #endregion
// #region Inbox Modal Store
const isInboxModalActive = ref(false);
const inboxModalProps = reactive({
    profileId: '',
    credentialId: '',
    messagesGroup: '',
    taskId: '',
});
// #endregion
export const useDashboardInboxModal = () => {
    const openInboxModal = async ({ profileId, credentialId, messagesGroup, taskId, }) => {
        if (selectedCredential.value._id !== credentialId) {
            try {
                await switchCredential(credentialId);
            }
            catch {
                return;
            }
        }
        inboxModalProps.profileId = profileId;
        inboxModalProps.credentialId = credentialId;
        inboxModalProps.messagesGroup = messagesGroup;
        inboxModalProps.taskId = taskId;
        isInboxModalActive.value = true;
    };
    const toggleInboxModal = (isShown) => {
        isInboxModalActive.value = isShown;
    };
    return {
        isInboxModalActive,
        inboxModalProps,
        openInboxModal,
        toggleInboxModal,
    };
};
