import { unref, onBeforeUnmount, onMounted } from 'vue';

/**
 * Autoresize textarea height
 * @param {import('vue').Ref<HTMLElement>|HTMLElement} textarea target textarea
 */
export const useTextareaResize = (textarea) => {
  const resizeTextarea = () => {
    const _textarea = unref(textarea);

    _textarea.style.height = 'auto';

    const style = window.getComputedStyle(_textarea);
    const borderWidth = parseInt(style.borderTopWidth) + parseInt(style.borderBottomWidth);
    const newHeight = _textarea.scrollHeight + borderWidth;

    _textarea.style.height = newHeight + 'px';
  };

  const resetTextareaHeight = () => {
    const _textarea = unref(textarea);

    _textarea.style.height = null;
  };

  onMounted(() => {
    unref(textarea)?.addEventListener('input', resizeTextarea, { passive: true });
  });

  onBeforeUnmount(() => {
    unref(textarea)?.removeEventListener('input', resizeTextarea, { passive: true });
  });

  return {
    resizeTextarea,
    resetTextareaHeight,
  };
};
