/*
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
*/
import { ApiError } from '@/errors';
import { getTimezoneOffset } from '@/utils/date/utils';

const useHttp = async (url, method = 'GET', body = null, params = {}, json = false) => {
  if (body) {
    if (json) {
      body = JSON.stringify(body);
    } else {
      const formData = new FormData();
      Object.keys(body).forEach(key => formData.append(key, body[key]));
      body = formData;
    }
  }

  const headers = {
    'x-timezone': getTimezoneOffset(),
  };

  if (json) {
    headers['Content-Type'] = 'application/json';
  }

  const space = new URLSearchParams(window.location.search).get('space');
  const token = localStorage.getItem('token');
  params = {
    ...params,
    token,
  };
  params = new URLSearchParams(params);

  if (space) {
    params.delete('space');
    if (space !== 'undefined' && !window.location.href.includes('/auth/')) { params.set('space', space); }
  }

  url = `${process.env.VUE_APP_BASE_URL}/public_api${url}?${params.toString()}`;

  const response = await fetch(url, {
    method,
    body,
    headers,
  });
  const data = await response.json();
  if (response.status === 401) {
    const isAuth = location.pathname.includes('auth');
    const isError = location.pathname.includes('error');
    const isVideo = location.pathname.includes('/v/');

    if (!(isAuth || isError || isVideo)) {
      setTimeout(() => (location.reload()), 400);
    }
  }

  if (!response.ok || !data.ok) {
    throw new ApiError(data.error || 'Something went wrong', response.status);
  }

  return data;
};

export { useHttp };
