export var TooltipAppearance;
(function (TooltipAppearance) {
    TooltipAppearance["DARK"] = "dark";
    TooltipAppearance["LIGHT"] = "light";
})(TooltipAppearance || (TooltipAppearance = {}));
export var TooltipTextAlign;
(function (TooltipTextAlign) {
    TooltipTextAlign["LEFT"] = "left";
    TooltipTextAlign["CENTER"] = "center";
    TooltipTextAlign["RIGHT"] = "right";
})(TooltipTextAlign || (TooltipTextAlign = {}));
export var TooltipPosition;
(function (TooltipPosition) {
    TooltipPosition["TOP"] = "top";
    TooltipPosition["TOP_LEFT"] = "top-left";
    TooltipPosition["TOP_RIGHT"] = "top-right";
    TooltipPosition["BOTTOM"] = "bottom";
    TooltipPosition["BOTTOM_LEFT"] = "bottom-left";
    TooltipPosition["BOTTOM_RIGHT"] = "bottom-right";
    TooltipPosition["LEFT"] = "left";
    TooltipPosition["LEFT_TOP"] = "left-top";
    TooltipPosition["LEFT_BOTTOM"] = "left-bottom";
    TooltipPosition["RIGHT"] = "right";
    TooltipPosition["RIGHT_TOP"] = "right-top";
    TooltipPosition["RIGHT_BOTTOM"] = "right-bottom";
})(TooltipPosition || (TooltipPosition = {}));
