/*
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
*/
import { computed } from 'vue';
import i18n from '@/i18n';

/** @type {import('vue').ComputedRef<Record<string, string>>} */
export const ACTIVITY_TYPES = computed(() => ({
  accept_connection_request: i18n.t('activity.acceptConnectionRequest'),
  open_email: i18n.t('activity.openEmail'),
  clicked_email_link: i18n.t('activity.clickedEmailLink'),
  clicked_message_link: i18n.t('activity.clickedMessageLink'),
  call_received: i18n.t('activity.callReceived'),
  replied_to_email: i18n.t('activity.repliedToEmail'),
  replied_to_linkedin_message: i18n.t('activity.repliedToLinkedInMessage'),
  replied_to_sms: i18n.t('activity.repliedToSms'),
}));

/** @type {Record<string, string>} */
export const ACTIVITY_TYPE_ICONS = Object.freeze({
  accept_connection_request: 'bx-user-plus',
  open_email: 'bx-envelope-open',
  clicked_email_link: 'bx-envelope',
  clicked_message_link: 'bx-message',
  call_received: 'bx-phone',
  replied_to_email: 'bx-reply-all',
  replied_to_linkedin_message: 'bxl-linkedin',
  replied_to_sms: 'bx-message-square-dots',
});
