import { eventBus } from '@/eventbus';
import { tierFeatures } from '@/data/tiersFeatures';

export const useUpgradeModal = (func, feature, needToShow = false) => {
  let newFunction;
  if (tierFeatures.statuses[feature]?.upgrade || needToShow) {
    newFunction = () => {
      eventBus.$emit('show-upgrade', feature);
    };
  } else {
    newFunction = async (...args) => {
      return await func(...args);
    };
  }
  return newFunction();
};
