function setTawk (hash, userId) {
  window.Tawk_LoadStart = new Date();

  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.charset = 'UTF-8';
  script.src = `https://embed.tawk.to/${hash}/${userId}`;
  script.async = true;
  script.setAttribute('crossorigin', '*');

  const head = document.getElementsByTagName('head')[0];
  head.appendChild(script);
}
export { setTawk };
