<!--
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
-->
<template>
  <div
    class="uikit-switcher"
    :class="`uikit-switcher_${size}`"
    @click="handleChange"
  >
    <span
      class="uikit-switcher__slider uikit-switcher__round"
      :class="{
        'uikit-switcher__slider_active': isChecked,
        'uikit-switcher__slider_disabled': isDisabled,
      }"
    >
      <i
        class="uikit-switcher__slider-toggle"
        :class="active"
      />
    </span>
  </div>
</template>

<script>
  export default {
    name: 'DefaultSwitcher',

    props: {
      size: {
        type: String,
        default: 'm',
        // s, m, l
      },
      isChecked: {
        type: Boolean,
        default: false,
      },
      isDisabled: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      active () {
        return this.isChecked
          ? `uikit-switcher__slider-toggle_${this.size} uikit-switcher__slider-toggle_${this.size}_active`
          : `uikit-switcher__slider-toggle_${this.size}`;
      },
    },
    methods: {
      handleChange () {
        if (this.isDisabled) return;
        this.$emit('change');
      },
    },
  };
</script>

<style lang='scss'>
.uikit-switcher {
  position: relative;
  display: block;

  &_xs {
    width: 26px;
    height: 16px;
  }

  &_s {
    width: 32px;
    height: 20px;
  }

  &_m {
    width: 40px;
    height: 24px;
  }

  &_l {
    width: 50px;
    height: 30px;
  }

  &__slider {
    transition: 0.5s;
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--bg-gray-08);
    -webkit-transition: 0.4s;
    transition: 0.4s;

    &_disabled {
      cursor: not-allowed;
    }

    &_active {
      background-color: var(--primary-cl);
    }
  }

  &__slider-toggle {
    position: absolute;
    left: 2px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 50%;

    &_xs {
      min-width: 12px;
      height: 12px;

      &_active {
        left: 12px;
      }
    }

    &_s {
      min-width: 16px;
      height: 16px;

      &_active {
        left: 14px;
      }
    }

    &_m {
      min-width: 20px;
      height: 20px;

      &_active {
        left: 18px;
      }
    }

    &_l {
      min-width: 26px;
      height: 26px;

      &_active {
        left: 22px;
      }
    }
  }

  &__round {
    border-radius: 35px;
  }
}
</style>
