<template>
  <div class="inbox-task-card__info">
    <p class="text_xs color_tertiary mb-05 ellipsis">
      {{ title }}
    </p>

    <component
      :is="selectorComponent"
      :is-editable="isEditable"
      :tooltip-options="tooltipOptions"
      :selected-option="selectedOption"
      :max-height="maxHeight"
      @update:selected-option="updateSelectedOption"
    >
      <span class="text_xs text_medium flex-row">
        <i
          v-if="selectedOption.icon"
          class="bx icon_size_16 mr-05"
          :class="[
            selectedOption.icon?.name,
            selectedOption.icon?.color
          ]"
        />

        <default-avatar
          v-else-if="selectedOption.avatar"
          class="mr-05"
          :photo-link="selectedOption.avatar?.url"
          size="xxxs"
        />

        <span class="ellipsis">
          {{ selectedOption.name }}
        </span>

        <i
          v-if="isEditable"
          class="bx bx-chevron-down icon_size_16"
        />
      </span>
    </component>
  </div>
</template>

<script setup>
  import DefaultAvatar from '@/components/base/uiKit/DefaultAvatar.vue';

  defineProps({
    title: {
      type: String,
      default: '',
    },
    tooltipOptions: {
      type: Array,
      default: () => [],
    },
    selectedOption: {
      type: Object,
      default: () => ({}),
    },
    isEditable: {
      type: Boolean,
      default: false,
    },
    selectorComponent: {
      type: Object,
      required: true,
    },
    maxHeight: {
      type: String,
      default: '',
    },
  });

  const emit = defineEmits(['update:selected-option']);

  const updateSelectedOption = (option) => {
    emit('update:selected-option', option);
  };
</script>

<style lang="scss">
.inbox-task-card__info {
  width: 100%;

  &-avatar {
    min-width: 16px;
    max-width: 16px;
    min-height: 16px;
    max-height: 16px;
  }
}
</style>
