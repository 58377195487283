/*
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
*/
import store from '@/store';
store.commit('mobile/SET_MOBILE_CHECK');
const CustomersPage = () => import('@/views/adminPage/CustomersPage');
const SettingWhiteLabel = () => import('@/views/adminPage/SettingWhiteLabel.vue');
const AdminPage = () => import('@/views/adminPage/AdminPage');
const StripePage = () => import('@/views/adminPage/StripePage');
const SettingsPage = () => import('@/views/adminPage/SettingsPage');
const BillingWhiteLabel = () => import('@/views/adminPage/BillingWhiteLabel');
const PricingWhiteLabel = () => import('@/views/adminPage/PricingWhiteLabel');
const FeaturesPage = () => import('@/views/adminPage/FeaturesPage');
const WhitelabesPage = () => import('@/views/adminPage/WhitelabesPage');
const MonitoringPage = () => import('@/views/adminPage/MonitoringPage');
const ChartsAdminPage = () => import('@/views/adminPage/ChartsAdminPage');
const PrepaidPage = () => import('@/views/adminPage/PrepaidPage');
const DemoPage = () => import('@/views/adminPage/DemoPage');
const HealthIndexAdminPage = () => import('@/views/adminPage/HealthIndexAdminPage');
const MobileCustomersPage = () => import('@/mobile/admin/MobileCustomersPage');
const CustomerInfo = () => import('@/components/adminPage/customersPage/CustomerInfo');
const IndexSettings = () => import('@/components/adminPage/settingAdmin/IndexSettings');
const isMobile = store.getters['mobile/isMobile'];
const NotificationPage = () => import('@/views/adminPage/NotificationPage');
const UpdatePricePage = () => import('@/components/adminPage/whiteLabelsPage/pricingComponents/UpdatePricePage');
const PricingFeaturesSettings = () => import('@/components/adminPage/whiteLabelsPage/PricingFeaturesSettings.vue');

let admin = [];

const meta = {
  layout: 'DefaultLayout',
  requiresAdmin: true,
  hasSidebar: true,
};

if (!isMobile) {
  admin = [
    {
      path: '/admin',
      name: 'admin',
      component: AdminPage,
      redirect: { name: 'customers' },
      meta,
      children: [

        {
          path: 'settings',
          name: 'admin-settings',
          component: SettingsPage,
          meta,
          children: [
            {
              path: 'notification/:whitelabel',
              name: 'notification',
              component: NotificationPage,
              meta,
            },
            {
              path: 'wl/:whitelabel',
              name: 'settings-wl',
              component: SettingWhiteLabel,
              meta,
            },
            {
              path: 'features-wl/:whitelabel',
              name: 'features-wl',
              component: FeaturesPage,
              meta,
              children: [
                {
                  path: ':tier',
                  name: 'features-wl-tier',
                  component: PricingFeaturesSettings,
                  meta,
                },
              ],
            },
            {
              path: 'pricing-wl/:whitelabel',
              name: 'pricing-wl',
              component: PricingWhiteLabel,
              meta,
            },
            {
              path: 'pricing-wl/:whitelabel/:type/:priceId',
              name: 'pricing-wl-update',
              component: UpdatePricePage,
              meta,
            },
            {
              path: 'billing-wl/:whitelabel',
              name: 'billing-wl',
              component: BillingWhiteLabel,
              meta,
            },
          ],
        },
        {
          path: 'whitelabels/:status?',
          component: WhitelabesPage,
          name: 'admin-whitelabels',
          meta,

        },
        {
          path: 'monitoring',
          name: 'monitoring',
          component: MonitoringPage,
          meta,
        },
        {
          path: 'charts-admin',
          name: 'charts-admin',
          component: ChartsAdminPage,
          meta,
        },
        {
          path: 'stripe',
          name: 'stripe',
          component: StripePage,
          meta,
        },
        {
          path: 'customers/:profileId?',
          name: 'customers',
          component: CustomersPage,
          meta,
        },
        {
          path: 'prepaid',
          name: 'prepaid',
          component: PrepaidPage,
          meta,
        },
        {
          path: 'health-index',
          name: 'health-index',
          component: HealthIndexAdminPage,
          meta,
          children: [
            {
              path: 'health-setting',
              name: 'health-setting',
              component: IndexSettings,
              meta,
            },
          ],
        },
        {
          path: 'demo-users',
          name: 'demo-users',
          component: DemoPage,
          meta,
        },
        {
          path: '*',
          redirect: { name: 'customers' },
        },
        // ...остальные вложенные маршруты
      ],
    },
  ];
} else {
  admin = [
    {
      path: '/admin',
      name: 'admin',
      component: MobileCustomersPage,
      meta,
      children: [
        {
          path: '/admin/customer/:profileId',
          name: 'admin-customer',
          component: CustomerInfo,
          meta,
        },
        {
          path: '*',
          redirect: { name: 'admin' },
        },
      ],
    },
  ];
}
export default admin;
