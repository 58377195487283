<!--
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
-->
<template>
  <li
    class="flex-row-between preference-label-item"
  >
    <div class="flex-row-between flex-gap-8">
      <i
        class="bx bxs-purchase-tag preference-label-item__name-icon"
        :style="labelColor ? `color: ${labelColor}` : ''"
      />
      <h3 class="text_s preference-label-item__name-text">
        {{ labelName }}
      </h3>
    </div>
    <div class="flex-row-between flex-gap-8">
      <p class="text_xs preference-label-item__type">
        {{ labelTitleType }}
      </p>
      <default-tooltip
        v-if="hasUpdate"
        :items="updateLabel"
        data-closable="updateLabel"
        :show.sync="showUpdate"
        class="text_xs preference-label-item__tooltip"
        :is-fulled="true"
        list-position="right"
        :has-triangle="false"
        width="150"
        max-height="200"
        @choose="$emit('choose', $event)"
      >
        <template #default>
          <default-button
            form="circle"
            icon-name="bx-dots-vertical-rounded"
            size="s"
            color="neutral"
          />
        </template>
      </default-tooltip>
    </div>
  </li>
</template>
  <script setup>
  // PreferenceLabelItem
  import { ref } from 'vue';
  import DefaultButton from '@/components/base/uiKit/DefaultButton.vue';
  import DefaultTooltip from '@/components/base/uiKit/DefaultTooltip';

  defineProps({
    labelName: {
      type: String,
      default: '',
    },
    labelType: {
      type: String,
      default: '',
    },
    labelColor: {
      type: String,
      default: '',
    },
    labelTitleType: {
      type: String,
      default: '',
    },
    hasUpdate: {
      type: Boolean,
      default: false,
    },
    updateLabel: {
      type: Array,
      default: () => [],
    },
  });

  const showUpdate = ref(false);

  </script>
  <style lang="scss">
  .preference-label-item {
        padding: 10px 12px;

        &__name-icon {
          color: var(--primary-cl);
        }

        &__name-text {
          font-weight: 400;
        }

        &__type {
          text-transform: capitalize;
        }

        &__tooltip {
          position: relative;
        }
   }
  </style>
