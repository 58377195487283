<!--
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
-->
<template>
  <div class="uikit-tooltip text_s">
    <div class="uikit-tooltip__inner">
      <div
        ref="tooltipTriggerRef"
        class="pointer"
        @click.stop="toggleTooltip"
      >
        <slot name="default" />
      </div>

      <ul
        v-show="isTooltipActive"
        ref="tooltipListRef"
        :class="[
          'uikit-tooltip__list',
          'uikit-tooltip__list_' + listPosition,
          { 'uikit-tooltip__list_fulled': isFulled },
        ]"
        :style="{
          maxHeight: maxHeight ? maxHeight + 'px' : maxHeight,
          width: listWidth,
          minWidth: listMinWidth,
          maxWidth: listMaxWidth,
        }"
      >
        <slot name="firstItemList" />

        <template v-if="filteredItems.length">
          <li
            v-for="item in filteredItems"
            :key="item.value"
            class="uikit-tooltip__item"
            :class="[
              item.customClass,
              {
                'uikit-tooltip__item_disabled': item.disabled,
                'uikit-tooltip__item_title color_tertiary': item.isTitle,
              }
            ]"
            @click.stop="selectTooltipItem(item)"
          >
            <i
              v-if="item.icon"
              :class="[
                'uikit-tooltip__icon',
                'bx',
                item.icon?.name || iconName,
                item.icon?.color || iconColor,
                item.icon?.size || iconSize,
                { 'uikit-tooltip__icon_right': iconPosition },
              ]"
            />

            <default-avatar
              v-if="item.avatar"
              size="xxxs"
              :photo-link="item.avatar?.url"
            />

            <p class="text_ellipsis">
              {{ item.name }}
            </p>

            <i
              v-if="markSelected && item.value === selectedItem.value"
              class="bx bx-check color_primary margin-left"
            />
          </li>
        </template>

        <template v-else>
          <span class="color_secondary">
            {{ emptyState }}
          </span>
        </template>

        <slot name="lastItemList" />
      </ul>

      <i
        v-show="show && hasTriangle"
        class="bx bxs-up-arrow uikit-tooltip__triangle"
      />
    </div>
  </div>
</template>

<script setup>
  import { ref, computed, watch } from 'vue';
  import DefaultAvatar from '@/components/base/uiKit/DefaultAvatar.vue';
  import { useClosable, ClosableData } from '@/use/useClosable';

  class TooltipItem {
    /**
     * @param {string} name
     * @param {string} value
     * @param {object} [icon=null]
     * @param {string} [icon.name=null]
     * @param {string} [icon.color=null]
     * @param {string} [icon.size=null]
     * @param {object} [avatar=null]
     * @param {string} [avatar.url=null]
     * @param {boolean} [disabled=false]
     * @param {boolean} [isTitle=false]
     * @param {string} [customClass=null]
     */
    constructor (
      name,
      value,
      icon = null,
      avatar = null,
      disabled = false,
      isTitle = false,
      customClass = null
    ) {
      this.name = name;
      this.value = value;
      this.icon = icon;
      this.avatar = avatar;
      this.disabled = disabled;
      this.isTitle = isTitle;
      this.customClass = customClass;
    }
  }

  const props = defineProps({
    items: {
      type: Array,
      default: () => [], // required нельзя ставить, иначе будет падать ошибка в консоль у зависимого компонента - DefaultTooltipBlock
    },
    selectedItem: {
      type: Object,
      default: () => ({}),
    },
    markSelected: {
      type: Boolean,
      default: false,
    },
    hideSelected: {
      type: Boolean,
      default: false,
    },
    listPosition: {
      type: String,
      default: 'left', // left, center, right
    },
    iconName: {
      type: String,
      default: '',
    },
    iconSize: {
      type: String,
      default: '',
    },
    iconColor: {
      type: String,
      default: '',
    },
    iconPosition: {
      type: String,
      default: '', // right
    },
    hasTriangle: {
      type: Boolean,
      default: true,
    },
    isFulled: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    maxHeight: {
      type: String,
      default: 'auto',
    },
    listWidth: {
      type: String,
      default: '',
    },
    listMaxWidth: {
      type: String,
      default: '',
    },
    listMinWidth: {
      type: String,
      default: '',
    },
    show: {
      type: Boolean,
      default: false,
    },
    dataClosable: {
      type: String,
      default: '',
    },
    emptyState: {
      type: String,
      default: function () {
        return this.$t('status.thereAreNoItems');
      },
    },
  });

  const emit = defineEmits(['select', 'update:show']);

  const filteredItems = computed(() => {
    if (props.hideSelected) {
      return props.items.filter((item) => (item.value !== props.selectedItem.value));
    }
    return props.items;
  });

  const tooltipTriggerRef = ref();
  const tooltipListRef = ref();

  const isTooltipActive = ref(false);

  watch(() => props.show, (newVal) => {
    isTooltipActive.value = newVal;
  });

  useClosable(
    new ClosableData(tooltipListRef, tooltipTriggerRef, isTooltipActive),
    () => emit('update:show', isTooltipActive.value)
  );

  const toggleTooltip = () => {
    if (props.isDisabled) return;

    isTooltipActive.value = !isTooltipActive.value;

    emit('update:show', isTooltipActive.value);
  };

  const selectTooltipItem = (item) => {
    if (item.disabled || item.isTitle) return;

    isTooltipActive.value = false;

    emit('select', item);
  };
</script>

<style lang="scss">
.uikit-tooltip {

  &__inner {
    position: relative;
  }

  &__list {
    cursor: default;
    min-width: 180px;
    overflow: auto;
    display: flex;
    flex-direction: column;
    gap: 4px;
    position: absolute;
    z-index: 1000;
    top: calc(100% + 4px);
    padding: 8px;
    border-radius: 12px;
    background-color: var(--background-color);
    box-shadow: 0 0 5px 1px var(--tooltip-shadow);

    &_right {
      right: 0;
    }

    &_center {
      left: 50%;
      transform: translateX(-50%);
    }

    &_fulled {
      width: 100%;
    }
  }

  &__item {
    @include flex-row;
    gap: 8px;
    padding: 4px;
    border-radius: 4px;
    white-space: nowrap;
    cursor: pointer;

    &_disabled {
      color: #00000050;
      pointer-events: none;
    }

    &_title {
      pointer-events: none;

      &:not(:first-child) {
        margin-top: 4px;
      }
    }

    &:hover {
      background: var(--active-bg-cl);
    }
  }

  &__icon {
    &_right {
      order: 2;
    };
  }

  &__triangle {
    position: absolute;
    right: 10px;
    top: 20px;
    display: block;
    color: var(--background-color);
  }
}
</style>
