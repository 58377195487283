import { onBeforeUnmount, onMounted, toRef } from 'vue';

export function useResize (targetElement, callback) {
  const targetElementRef = toRef(targetElement, 'value');
  const resizeObserver = new ResizeObserver(entries => {
    const element = entries[0].contentRect;
    callback(element);
  });
  onMounted(() => {
    resizeObserver.observe(targetElementRef.value);
  });
  onBeforeUnmount(() => {
    resizeObserver.unobserve(targetElementRef.value);
    resizeObserver.disconnect();
  });
}
