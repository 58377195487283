const regexps = {
  // разрешенные пути (не нужно редиректить)
  noCredentials: [
    /credentials\/add/,
    /billing/,
    /workspace(.)*/,
    /accept-invite/,
    /pricing(.)*/,
    /onboarding(.)*/,
    /user(.)*/,
    /privacy-policy/,
    /terms-conditions/,
    /video/,
    /dashboard/,
    /crm/,
    /payment-in-progress/,
  ],
  unpaid: [
    /workspace(.)*/,
    /accept-invite/,
    /user(.)*/,
    /onboarding(.)*/,
    /pricing(.)*/,
    /crm/,
    /settings/,
    /billing-warning/,
  ],
  paymentInProgress: [
    /payment-in-progress/,
  ],
  // запрещенные пути (откуда нужно редиректнуть)
  afterPayment: [
    /billing(.)*/,
    /prepaid/,
  ],
  authorized: [
    /auth\//,
    /\/space\/(?!accept-invite)/,
  ],
};

class RedirectRule {
  /**
     * @param {{name: string}} redirectName
     * @param {boolean} [allowedPaths=true]
     * @param {RegExp[]} regexps
     */
  constructor (redirectName, regexps, allowedPaths = true) {
    this.defaultRedirect = { name: redirectName };
    this.allowedPaths = allowedPaths;
    this.regexps = regexps;
  }

  /** @param {string} path */
  check (path, isAdmin) {
    let { regexps, allowedPaths } = this;
    // если админ - добавить в разрешенные пути, иначе добавить в запрещенные пути
    if ((isAdmin && allowedPaths) || (!isAdmin && !allowedPaths)) {
      regexps = [...regexps, /admin(.)*/];
    }
    const isIncluded = regexps.some((regex) => regex.test(path));
    return allowedPaths ? isIncluded : !isIncluded;
  }
}

const redirectRules = {
  noCredentials: new RedirectRule('credentials-add', regexps.noCredentials),
  unpaid: new RedirectRule('billing-warning', regexps.unpaid),
  dashboard: new RedirectRule('dashboard', regexps.noCredentials),
  paymentInProgress: new RedirectRule('paymentInProgress', regexps.paymentInProgress),
  afterPayment: new RedirectRule('dashboard', regexps.afterPayment, false),
  authorized: new RedirectRule('campaigns', regexps.authorized, false),
};
export { RedirectRule, redirectRules };
