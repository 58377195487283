var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"uikit-tooltip text_s"},[_c('div',{staticClass:"uikit-tooltip__inner"},[_c('div',{ref:"tooltipTriggerRef",staticClass:"pointer",on:{"click":function($event){$event.stopPropagation();return _setup.toggleTooltip.apply(null, arguments)}}},[_vm._t("default")],2),_c('ul',{directives:[{name:"show",rawName:"v-show",value:(_setup.isTooltipActive),expression:"isTooltipActive"}],ref:"tooltipListRef",class:[
        'uikit-tooltip__list',
        'uikit-tooltip__list_' + _vm.listPosition,
        { 'uikit-tooltip__list_fulled': _vm.isFulled },
      ],style:({
        maxHeight: _vm.maxHeight ? _vm.maxHeight + 'px' : _vm.maxHeight,
        width: _vm.listWidth,
        minWidth: _vm.listMinWidth,
        maxWidth: _vm.listMaxWidth,
      })},[_vm._t("firstItemList"),(_setup.filteredItems.length)?_vm._l((_setup.filteredItems),function(item){return _c('li',{key:item.value,staticClass:"uikit-tooltip__item",class:[
            item.customClass,
            {
              'uikit-tooltip__item_disabled': item.disabled,
              'uikit-tooltip__item_title color_tertiary': item.isTitle,
            }
          ],on:{"click":function($event){$event.stopPropagation();return _setup.selectTooltipItem(item)}}},[(item.icon)?_c('i',{class:[
              'uikit-tooltip__icon',
              'bx',
              item.icon?.name || _vm.iconName,
              item.icon?.color || _vm.iconColor,
              item.icon?.size || _vm.iconSize,
              { 'uikit-tooltip__icon_right': _vm.iconPosition },
            ]}):_vm._e(),(item.avatar)?_c(_setup.DefaultAvatar,{attrs:{"size":"xxxs","photo-link":item.avatar?.url}}):_vm._e(),_c('p',{staticClass:"text_ellipsis"},[_vm._v(" "+_vm._s(item.name)+" ")]),(_vm.markSelected && item.value === _vm.selectedItem.value)?_c('i',{staticClass:"bx bx-check color_primary margin-left"}):_vm._e()],1)}):[_c('span',{staticClass:"color_secondary"},[_vm._v(" "+_vm._s(_vm.emptyState)+" ")])],_vm._t("lastItemList")],2),_c('i',{directives:[{name:"show",rawName:"v-show",value:(_vm.show && _vm.hasTriangle),expression:"show && hasTriangle"}],staticClass:"bx bxs-up-arrow uikit-tooltip__triangle"})])])
}
var staticRenderFns = []

export { render, staticRenderFns }