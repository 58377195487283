import { computed } from 'vue';
import { useStore } from '@/store';
import { useSnack } from '@/lib/useSnack';
// #region Composables
const store = useStore();
const { $snack } = useSnack();
// #endregion
const credentialsList = computed(() => {
    return store.getters['credentials/credentialsList'];
});
const selectedCredential = computed(() => {
    return store.getters['credentials/selectedCredential'];
});
export const useCredentials = () => {
    const switchCredential = async (id) => {
        const targetCredential = _getCredentialById(id);
        if (!targetCredential) {
            $snack.error('Can\'t find such credential');
            throw new Error('Can\'t find such credential');
        }
        await store.dispatch('credentials/SET_SELECTED_CREDENTIAL', targetCredential);
        $snack.create({
            type: 'warning',
            message: 'Selected credential have been switched to ' + targetCredential.full_name + '!',
            duration: 10000,
        });
    };
    const getCredentialTimezoneOffset = (pcTimezone = false) => {
        const selectedCredTzOffset = selectedCredential.value?.timezone_offset;
        const pcTzOffset = new Date().getTimezoneOffset() * -1;
        return pcTimezone ? pcTzOffset : selectedCredTzOffset ?? pcTzOffset;
    };
    // #region Helpers
    function _getCredentialById(_id) {
        return credentialsList.value.find((credential) => {
            return credential._id === _id;
        }) || null;
    }
    // #endregion
    return {
        credentialsList,
        selectedCredential,
        switchCredential,
        getCredentialTimezoneOffset,
    };
};
