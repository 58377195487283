/*
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
*/
import { TEMPLATES } from '@/utils/workflows/templates';
import { mapGetters } from 'vuex';

export const templatesMixin = {
  data () {
    return {
      templates: TEMPLATES.value,
      // returns placeholder with brackets -> `{first_name}`
      placeholderRegExp: /\{.*?\}/gm,
      // returns placeholder without brackets -> `first_name`
      placeholderValueRegExp: /[^{}]+(?=\})/gm,
      defaultPlaceholderReservedSpace: 10,
    };
  },

  computed: {
    ...mapGetters({
      workflowCustomField: 'workflows/workflowCustomField',
    }),
    allDefaultPlaceholders () {
      return this.templates.reduce((prev, section) => {
        return [...prev, ...section.data];
      }, []);
    },
    templatesRegexps () {
      const templatesRegexps = [];
      const allTemplates = this.allDefaultPlaceholders;
      allTemplates.forEach(template => {
        if (!template.disabled) {
          const regexString = template.fallback ? `{${template.value}(:[^}]+)}` : `{${template.value}(:[^}]+)?}`;
          templatesRegexps.push(new RegExp(regexString));
        }
      });
      return templatesRegexps;
    },
  },
  methods: {
    getDefaultPlaceholderReservedSpace (placeholderValue) {
      const defaultPlaceholder = this.allDefaultPlaceholders.find((template) => {
        return template.value === placeholderValue;
      });
      return defaultPlaceholder?.space || this.defaultPlaceholderReservedSpace;
    },
    getTextWithoutPlaceholders (text) {
      return text.replaceAll(this.placeholderRegExp, '');
    },
    getPlaceholdersReservedSpaceSum (text) {
      const placeholders = text.match(this.placeholderValueRegExp);
      return placeholders?.reduce((acc, placeholder) => {
        return acc + this.getDefaultPlaceholderReservedSpace(placeholder);
      }, 0) || 0;
    },
    getAvgTextLength (text) {
      const string = this.getTextWithoutPlaceholders(text);
      return string.length + this.getPlaceholdersReservedSpaceSum(text);
    },
  },
};
