export const DefaultIconProps = {
    name: {
        type: String,
        required: true,
        default: '',
    },
    color: {
        type: String,
        default: 'tertiary',
    },
    size: {
        type: Number,
        default: 16,
    },
    customClass: {
        type: String,
        default: '',
    },
};
