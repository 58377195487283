<template>
  <div>
    <transition-group
      name="activity-timeline"
      tag="section"
      class="activity-timeline"
    >
      <template v-if="!isActivityEmpty">
        <section
          v-for="(activityList, date) in activity"
          :key="date"
          class="activity-timeline__section"
        >
          <transition-group
            :key="'activity-list-' + date"
            name="activity-timeline"
            tag="ul"
            class="activity-timeline__list"
          >
            <div
              v-if="activityList.length !== 0"
              :key="'date-label-' + date"
              class="activity-timeline__date-label-wrapper"
            >
              <span class="activity-timeline__date-label text_xs text_medium">
                {{ moment(date).format('dddd, DD MMM YYYY') }}
              </span>
            </div>

            <activity-timeline-item
              v-for="item in activityList"
              :key="item._id"
              :activity="item"
              class="activity-timeline__list-item"
              :is-inbox-modal-disabled="isInboxModalDisabled"
            />
          </transition-group>
        </section>
      </template>

      <template v-else>
        <div
          key="activityTimelineEmptyState"
          class="parent-size flex-column-centered"
        >
          <p class="text_s text_center color_tertiary">
            {{ emptyStateText }}
          </p>
        </div>
      </template>
    </transition-group>
  </div>
</template>

<script setup>
  import { computed } from 'vue';
  import ActivityTimelineItem from '@/components/dashboard/activity/ActivityTimelineItem.vue';
  import moment from 'moment';

  const props = defineProps({
    activity: {
      type: Object,
      default: () => ({}),
    },
    emptyStateText: {
      type: String,
      default: function () {
        return this.$t('dashboardPage.activity.emptyState.timeline');
      },
    },
    isInboxModalDisabled: {
      type: Boolean,
      default: false,
    },
  });

  // // #region Activity
  const isActivityEmpty = computed(() => {
    return Object.values(props.activity).map((activity) => {
      return activity.length === 0;
    }).every((isEmpty) => isEmpty);
  });
  // // #endregion
</script>

<style lang="scss">
.activity-timeline {
  position: relative;
  height: 100%;

  &__date-label-wrapper {
    position: sticky;
    z-index: 1;
    padding: 16px 0;
    top: 0;
  }

  &__date-label {
    display: block;
    margin: 0 auto;
    width: max-content;
    background-color: var(--background-color);
    padding: 8px 16px;
    box-shadow: 0px 2px 8px 0px rgba(35, 44, 96, 0.12);
    border-radius: 999px;
  }

  &__list {
    position: relative;

    &-item {
      margin: 0 auto;
    }
  }
}

.activity-timeline-enter-active,
.activity-timeline-leave-active,
.activity-timeline-move {
  transition: all .3s ease-out;
}

.activity-timeline-leave-active {
  position: absolute;
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
}

.activity-timeline-enter,
.activity-timeline-leave-to {
  opacity: 0;
}
</style>
