<template>
  <default-modal
    name="recentPromptsModal"
    :show="show"
    width="500px"
    @update:show="toggleModal"
  >
    <section
      ref="loaderOverlayRef"
      class="p-3 vld-parent"
    >
      <recent-prompt-view
        v-if="selectedPrompt"
        :prompt="selectedPrompt"
        @back="selectedPrompt = null"
        @delete="deletePrompt"
        @close="toggleModal(false)"
        @insert-message="insertMessage"
      />

      <recent-prompts-list-view
        v-else
        :prompts="recentPrompts"
        :is-loading="isLoading"
        @load-more-prompts=" loadMorePrompts"
        @select="selectedPrompt = $event"
        @clear-recent-prompts="clearPrompts"
      />
    </section>
  </default-modal>
</template>

<script setup>
  import { ref, watch, nextTick, computed } from 'vue';
  import DefaultModal from '@/components/base/DefaultModal.vue';
  import RecentPromptView from '@/modals/recentPrompts/RecentPromptView.vue';
  import RecentPromptsListView from '@/modals/recentPrompts/RecentPromptsListView.vue';
  import { loadingWrapper, useLoader } from '@/use/loader';
  import { fetchRecentPrompts, deleteRecentPrompt, clearRecentPrompts } from '@/api/AIMethods';

  const props = defineProps({
    show: {
      type: Boolean,
      required: true,
      default: false,
    },
    credentialsId: {
      type: String,
      required: true,
    },
    profileId: {
      type: String,
      required: true,
    },
    promptsRefetchTrigger: {
      type: Boolean,
      default: false,
    },
  });

  const emit = defineEmits([
    'update:show',
    'update:recent-prompts',
    'insert-message',
    'clear-recent-prompts',
    'delete-recent-prompt',
    'load-more-prompts',
  ]);

  const recentPrompts = ref([]);

  const selectedPrompt = ref(null);

  watch(() => props.show, async (newShow) => {
    if (newShow) {
      // Hack against full-screen loader
      await nextTick();
      recentPrompts.value = await getRecentPrompts(0);
    } else {
      shouldLoadMorePrompts.value = true;
    }
  });

  watch(() => props.promptsRefetchTrigger, async () => {
    if (!props.show) return;
    recentPrompts.value = await getRecentPrompts();
  });

  // #region Loader
  const loaderOverlayRef = ref(null);
  const isLoading = ref(false);
  const { loaderWatcher } = useLoader({ container: loaderOverlayRef });
  loaderWatcher(isLoading);
  // #endregion

  // #region Prompts handlers
  const promptsOffset = computed(() => recentPrompts.value.length);

  const shouldLoadMorePrompts = ref(true);

  async function getRecentPrompts (offset = 0) {
    return await loadingWrapper(fetchRecentPrompts, isLoading)(props.credentialsId, props.profileId, offset);
  }

  async function clearPrompts () {
    await loadingWrapper(clearRecentPrompts, isLoading)(props.credentialsId, props.profileId);
    recentPrompts.value = [];
  }

  async function deletePrompt (id) {
    await loadingWrapper(deleteRecentPrompt, isLoading)(id);
    const promptId = recentPrompts.value.findIndex((prompt) => prompt._id === id);
    recentPrompts.value.splice(promptId, 1);
  }

  async function loadMorePrompts () {
    if (isLoading.value || !shouldLoadMorePrompts.value) return;

    const response = await getRecentPrompts(promptsOffset.value);
    if (!response?.length) {
      shouldLoadMorePrompts.value = false;
      return;
    }

    recentPrompts.value.push(...response);
  }
  // #endregion

  function toggleModal (show) {
    emit('update:show', show);
    selectedPrompt.value = null;
  }

  function insertMessage (message) {
    emit('insert-message', message);
    toggleModal(false);
  }
</script>

<style lang="scss">
.recent-prompt {
  padding: 2px 12px;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color .2s ease;

  &:hover {
    background-color: var(--color-surface-50);
  }
}
</style>
