<template>
  <ul>
    <li class="flex-row flex-justify-content-between">
      <div>
        <h3 class="text_s text_medium">
          Include weekend days
        </h3>

        <p class="mt-05 text_xs color_tertiary">
          Take into account non-working days when adding tasks
        </p>
      </div>

      <default-switcher
        size="xs"
        :is-checked="isWeekendDaysEnabled"
        @change="toggleWeekendDays"
      />
    </li>
  </ul>
</template>

<script setup>
  import { ref } from 'vue';
  import DefaultSwitcher from '@/components/base/uiKit/DefaultSwitcher.vue';

  const isWeekendDaysEnabled = ref(false);

  const toggleWeekendDays = () => {
    // TODO: integrate api to manual approval include weekend switcher
    isWeekendDaysEnabled.value = !isWeekendDaysEnabled.value;
  };
</script>

<style lang="scss">

</style>
