import { useSnack } from '@/lib/useSnack';
/** @type  {use.notifyError} */
const notifyError = (func, onErrorReturn = null) => {
  const { $snack } = useSnack();
  const newFunction = async (...args) => {
    try {
      // @ts-ignore
      return await func(...args);
    } catch (e) {
      $snack.error(e.message);
      return onErrorReturn;
    }
  };
  return newFunction;
};
export { notifyError };
