<!--
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
-->
<template>
  <header
    class="crm-header"
  >
    <div
      :class="[
        'crm-header__left-side',
        { 'crm-header__disabled-block': !allPipelines.length }
      ]"
    >
      <default-input
        :value.sync="query"
        :placeholder="$t('pipelinesPage.header.searchPlaceholder')"
        :debounce="300"
        size="xs"
        icon="bx-search"
        @input="search"
      />
    </div>
    <div class="crm-header__right-side">
      <div
        v-if="owners.length && loaded"
        v-closable="{
          exclude: ['selectOwner'],
          handler: () => isSelectOwner = false,
          params: 'isSelectOwner',
        }"
        :class="[
          'crm-header__select-owner',
          { 'crm-header__disabled-block': !allPipelines.length }
        ]"
        @click="isSelectOwner = !isSelectOwner"
      >
        <div
          ref="selectOwner"
          class="crm-header__selected-owner"
        >
          <img
            v-if="selectedOwner.img"
            loading="lazy"
            :src="selectedOwner.img"
            class="crm-header__owner-avatar"
            alt="owner avatar"
          >
          <i
            v-else
            class="bx bx-user-circle color_secondary crm-header__owner-avatar crm-header__owner-avatar_icon"
          />
          <input
            :value="selectedOwner.name || 'All Owners'"
            readonly="true"
            class="crm-header__owner-input color_black"
            :placeholder="$t('pipelinesPage.header.selects.owner.placeholder')"
            type="text"
          >
          <i
            class="bx crm-header__selected-owner-arrow bx-chevron-down"
          />
        </div>
        <ul
          ref="selectOwner"
          class="crm-header__select-owner-items"
          :class="{'crm-header__select-owner-items_open': isSelectOwner,
                   'crm-header__select-owner-items_close': !isSelectOwner,
          }"
        >
          <li
            v-for="owner in owners"
            :key="owner._id"
            class="crm-header__select-owner-item"
            @click.stop="selectOwner(owner)"
          >
            <img
              v-if="owner.img"
              loading="lazy"
              :src="owner.img"
              class="crm-header__owner-avatar"
              alt="owner avatar"
            >
            <i
              v-else
              class="bx bx-user-circle color_secondary crm-header__owner-avatar crm-header__owner-avatar_icon"
            />
            <span class="crm-header__text crm-header__text_hidden">{{ owner.name }}</span>
          </li>
        </ul>
      </div>
      <default-select
        :key="selectedPipeline.name"
        :class="{ 'crm-header__disabled-block': !allPipelines.length }"
        data-closable="pipeline"
        :items="pipelines"
        :value="selectedPipeline._id ? selectedPipeline.name : ''"
        input-size="xs"
        input-placeholder="Select Pipeline"
        @select="selectPipeline"
      />
      <default-select
        :class="{ 'crm-header__disabled-block': !allPipelines.length }"
        :items="statusFilters"
        data-closable="status"
        :read-only="true"
        :value="selectedStatus._id ? selectedStatus.name : $t('pipelinesPage.header.selects.deals.all')"
        input-size="xs"
        :input-placeholder="$t('pipelinesPage.header.selects.deals.placeholder')"
        @select="selectStatus"
      />
      <crm-switcher
        :crms="crms"
        @select-crm="$emit('select-crm', $event)"
      />

      <default-button
        v-if="$route.params.integration !== 'default'"
        color="neutral"
        form="square"
        icon-name="bx-refresh"
        size="m"
        @action="$emit('refresh')"
      />

      <default-tooltip
        v-if="$route.params.integration === 'default'"
        :class="[
          'crm-table-column__tooltip',
          { 'crm-header__disabled-block': !allPipelines.length }
        ]"
        data-closable="pipelineMenu"
        :has-triangle="false"
        :size="'s'"
        :show.sync="isShowTooltip"
        :items="menuFields"
        list-position="right"
        @choose="selectMenuItem"
      >
        <template #default>
          <default-button
            class="crm-header__btn-delete"
            color="neutral"
            form="square"
            icon-name="bx-dots-horizontal-rounded"
            size="m"
          />
        </template>
      </default-tooltip>
    </div>
  </header>
</template>

<script>
  import DefaultButton from '@/components/base/uiKit/DefaultButton';
  import DefaultTooltip from '@/components/base/uiKit/DefaultTooltip';
  import { loaderMixin } from '@/mixins/loaderMixin';
  import DefaultSelect from '@/components/base/uiKit/DefaultSelect';
  import DefaultInput from '@/components/base/uiKit/DefaultInput';
  import CrmSwitcher from '@/components/CRMIntegration/CrmSwitcher';

  export default {
    name: 'CrmPageHeader',
    components: {
      DefaultButton,
      DefaultSelect,
      DefaultInput,
      DefaultTooltip,
      CrmSwitcher,
    },
    mixins: [loaderMixin],
    props: {
      owners: {
        type: Array,
        default: () => [],
      },
      pipelines: {
        type: Array,
        default: () => [],
      },
      allPipelines: {
        type: Array,
        default: () => [],
      },
      crms: {
        type: Array,
        default: () => [],
      },
      selectedPipeline: {
        type: Object,
        default: () => {},
      },
      selectedOwner: {
        type: Object,
        default: () => {},
      },
      selectedStatus: {
        type: Object,
        default: () => {},
      },
    },
    data () {
      return {
        query: '',
        isSelectOwner: false,
        isShowTooltip: false,
        statusFilters: [
          {
            name: this.$t('pipelinesPage.header.selects.deals.all'),
            _id: '',
          },
          {
            name: this.$t('pipelinesPage.header.selects.deals.open'),
            _id: 'open',
          },
          {
            name: this.$t('pipelinesPage.header.selects.deals.won'),
            _id: 'won',
          },
          {
            name: this.$t('pipelinesPage.header.selects.deals.lost'),
            _id: 'lost',
          },
        ],
        menuFields: [
          {
            name: this.$t('pipelinesPage.header.menu.actions.addPipeline'),
            iconName: 'bx-plus',
            _id: 'add',
          },
          {
            name: this.$t('pipelinesPage.header.menu.actions.renamePipeline'),
            iconName: 'bx-edit',
            _id: 'rename',
          },
          {
            name: this.$t('pipelinesPage.header.menu.actions.deletePipeline'),
            iconName: 'bx-trash',
            _id: 'delete',
          },
        ],
      };
    },
    methods: {
      search () {
        this.$emit('search-name', this.query);
      },
      async selectPipeline (pipeline) {
        this.$emit('select-pipeline', pipeline);
      },
      async selectOwner (owner) {
        this.isSelectOwner = false;
        this.$emit('select-owner', owner);
      },
      selectStatus (status) {
        this.$emit('select-status', status);
      },
      selectMenuItem (item) {
        if (item._id === 'add') {
          this.$emit('add-pipeline');
        } else if (item._id === 'rename') {
          this.$emit('rename-pipeline');
        } else this.$emit('delete-pipeline');
      },
    },
  };
</script>

<style lang="scss">
.crm-header {
      padding: 12px;
      gap: 4px;
      display: flex;
      align-items: center;
      background: var(--table-bg);
      z-index: 1;
      justify-content: space-between;
      &__left-side {
        display: flex;
        gap: 4px;
        align-items: center;
      }
      &__right-side {
        display: flex;
        gap: 4px;
        align-items: center;
      }
 &__dot {
      background: var(--secondary-text-color);
      width: 4px;
      height: 4px;
      border-radius: 50%;
      display: flex;
      align-items: center;
    }
    &__select-crm {
      position: relative;
      display: flex;
      flex-flow: column;
      transition: 300ms;
    }
    &__selected-crm {
      z-index: 99;
      padding: 3px 4px 0px 4px;
      position: relative;
      cursor: pointer;
      &-arrow {
        position: absolute;
        top: 15px;
        right: 16px;
        font-size: 8px;
        color: #fff;
        z-index: 101;
        transition: 400ms;
        &_open {
          transform: rotate(180deg);
        }
      }
    }
    &__select-items {
      position: absolute;
      padding: 0px 4px;
      background: var(--background-color);
      border-radius: 8px;
      box-shadow: 0px 2px 4px rgba(10, 32, 51, 0.06), 0px 0px 8px rgba(10, 32, 51, 0.05);
      transition: 300ms;
      &_open {
        top: 40px;
        opacity: 1;
        z-index: 9;
      }
      &_close {
        top: 0px;
        opacity: 0;
        z-index: 0;
      }
    }
    &__select-item {
      cursor: pointer;
      &:hover {
        opacity: 0.7;
      }
    }
    &__owner-avatar {
      width: 15px;
      height: 15px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      &_icon {
        font-size: 15px;
      }
    }
  &__owner-input {
    background: var(--table-bg);
  }
    &__select-owner {
      padding: 8px 12px;
      border: 1px solid #DCE5ED;
      position: relative;
      border-radius: 8px;
      font-size: 11px;
      transition: 300ms;
      & input {
        width: 100%;
        border: none;
        font-size: 11px;
        line-height: 16px;
      }
    }
    &__selected-owner {
      display: flex;
      align-items: center;
      z-index: 99;
      gap: 8px;
    }
    &__select-owner-items {
      position: absolute;
      width: 100%;
      background: var(--background-color);
      border-radius: 8px;
      padding: 8px;
      box-shadow: 0px 2px 4px rgba(10, 32, 51, 0.06), 0px 0px 8px rgba(10, 32, 51, 0.05);
      transition: 300ms;
      left: 0;
      &_open {
        top: calc(100% + 4px);
        z-index: 9;
      }
      &_close {
        display: none;
      }
    }
    &__select-owner-item {
      display: flex;
      cursor: pointer;
      gap: 8px;
      align-items: center;
      padding: 8px;
      z-index: 0;
      &:hover {
       background: var(--hover-secondary);
       border-radius: 8px;
      }
    }
    // &__filters {
    //   position: sticky;
    //   top: 16px;
    //   max-width: 320px;
    // }
    &__btn-delete {
      margin-left: 2px;
    }
  &__text {
    display: flex;
    align-items: center;
    gap: 4px;
    &_hidden {
      max-width: 80%;
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &__disabled-block {
    opacity: 0.4;
    pointer-events: none ;
  }
}
</style>
