<!--
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
-->
<template>
  <validation-observer
    v-slot="{ passes }"
    :key="key"
  >
    <form
      ref="vldParent"
      class="vld-parent"
      @submit.prevent="passes(changePassword)"
    >
      <div class="preferences-page__form-control">
        <label class="text_m">
          {{ $t('userSettings.settingsList.password.newPassword.label').concat('*') }}
        </label>
        <default-input
          :value.sync="newPassword"
          :has-password="true"
          vid="confirmation"
          name="confirmation"
          type="password"
          validation-string="required|min:8"
          class="preferences-page__form-input"
          size="s"
          :placeholder="$t('userSettings.settingsList.password.newPassword.placeholder')"
        />
      </div>

      <div class="preferences-page__form-control">
        <label class="text_m">
          {{ $t('userSettings.settingsList.password.passwordConfirmation.label').concat('*') }}
        </label>
        <default-input
          :value.sync="confirmation"
          :has-password="true"
          name="Password confirmation"
          type="password"
          validation-string="required|confirmed:confirmation"
          class="preferences-page__form-input"
          size="s"
          :placeholder="$t('userSettings.settingsList.password.passwordConfirmation.placeholder')"
        />
      </div>

      <div class="preferences-page__form-group margin-left mb-2">
        <p class="text_s text_weight_accent">
          {{ $t('userSettings.settingsList.password.requirements.title') }}
        </p>
        <p class="text_s color_secondary text_weight_accent">
          {{ $t('userSettings.settingsList.password.requirements.subtitle') }}
        </p>
        <ul class="text_s color_secondary">
          <li>
            {{ $t('userSettings.settingsList.password.requirements.reuirement_0') }}
          </li>
          <!-- <li>At least one lowercase character</li>
          <li>At least one uppercase character</li>
          <li>At least one number, symbol, or whitespace character</li> -->
        </ul>
      </div>

      <default-button
        class="margin-left"
        type="submit"
        :title="$t('userSettings.settingsList.password.buttons.changePassword')"
        color="primary"
        form="rectangle"
        size="m"
      />
    </form>
  </validation-observer>
</template>
<script>
  import DefaultInput from '@/components/base/uiKit/DefaultInput';
  import DefaultButton from '@/components/base/uiKit/DefaultButton';
  import { loaderMixin } from '@/mixins/loaderMixin';
  import { changePassword } from '@/api/userMethods';
  import { ValidationObserver } from 'vee-validate';
  export default {
    components: {
      DefaultInput,
      DefaultButton,
      ValidationObserver,
    },
    mixins: [loaderMixin],
    data () {
      return {
        newPassword: '',
        confirmation: '',
        key: 'userPassword',
      };
    },
    mounted () {
      this.key = `${this.key}_${+new Date()}`;
    },
    methods: {
      async changePassword () {
        try {
          this.loaded = false;
          await changePassword({
            new_password: this.newPassword,
            new_password_confirmation: this.confirmation,
          });
          this.$noty.success(
            this.$t('userSettings.settingsList.password.notifications.passwordChanged')
          );
        } catch (e) {
          this.$noty.error(e.message);
        } finally {
          this.loaded = true;
        }
      },
    },
  };
</script>
