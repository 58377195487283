/*
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
*/

const state = () => ({
  myTasksCard: [],
});

const getters = {
  myTasksCard: (state) => {
    return state.myTasksCard;
  },
};

const mutations = {
  SET_MY_TASKS_CARD (state, payload) {
    state.myTasksCard = payload;
  },
  ADD_MY_TASKS_CARD (state, payload) {
    state.myTasksCard.push(...payload);
  },
};

const actions = {
  SET_MY_TASKS_CARD ({ commit }, payload) {
    commit('SET_MY_TASKS_CARD', payload);
  },
  ADD_MY_TASKS_CARD ({ commit }, payload) {
    commit('ADD_MY_TASKS_CARD', payload);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
