<!--
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
-->
<template>
  <div
    v-if="index <= 2"
    class="default-avatar"
  >
    <img
      v-if="!!photoLink"
      loading="lazy"
      class="default-avatar__image"
      :src="setSrcImg(photoLink || '')"
      @error="setAltImg"
    >
    <div
      v-else-if="!photoLink && name"
      class="default-avatar__name"
    >
      <span class="color_secondary">
        {{ name[0] }}
      </span>
    </div>
  </div>
</template>

<script>
  import { setAltImg } from '@/mixins/setAltImg';
  export default {
    name: 'CrmAvatarItem',
    mixins: [setAltImg],
    props: {
      photoLink: {
        type: String,
        default: '',
      },
      name: {
        type: String,
        default: '',
      },
      index: {
        type: Number,
        default: 0,
      },
    },
  };
</script>

<style lang="scss" scoped>
.default-avatar {
  img {
    display: block;
  }
  &__name {
    background: var(--secondary-btn-bg);
    font-weight: bold;
    border-radius: 100%;
    width: 16px;
    height: 16px;
    font-size: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__image {
    width: 16px;
    height: 16px;
    position: relative;
    border-radius: 50%;
  }
}
</style>
