<template>
  <section>
    <header class="mb-05">
      <default-button
        :title="$t('actions.back')"
        color="transparent"
        icon-name="bx-left-arrow-alt"
        size="xxxs"
        :has-paddings="false"
        @action="emit('back')"
      />
    </header>

    <main>
      <article>
        <header>
          <h1 class="mb-3 heading_l">
            {{ title }}
          </h1>
        </header>

        <main>
          <span class="mb-05 color_tertiary text_xs">
            {{ $tc('common.message') }}
          </span>

          <p class="text_s">
            {{ message }}
          </p>
        </main>
      </article>
    </main>

    <footer class="mt-3 flex-row flex-gap-8 flex-justify-content-end">
      <default-button
        class="margin-right"
        :title="$t('actions.delete')"
        color="danger"
        @action="deletePrompt"
      />

      <default-button
        :title="$t('actions.cancel')"
        color="neutral"
        @action="emit('close')"
      />

      <default-button
        :title="$t('modals.recentPrompts.actions.insertMessage')"
        color="primary"
        @action="emit('insert-message', message)"
      />
    </footer>
  </section>
</template>

<script setup>
  import DefaultButton from '@/components/base/uiKit/DefaultButton.vue';

  const props = defineProps({
    prompt: {
      type: Object,
      required: true,
    },
  });

  const emit = defineEmits(['back', 'delete', 'close', 'insert-message']);

  const title = props.prompt.goal;
  const message = props.prompt.ai_response;

  function deletePrompt () {
    emit('back');
    emit('delete', props.prompt._id);
  }
</script>
