<!--
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
-->
<template>
  <div
    class="workflow-step continue-step"
    :class="[{ 'continue-step_active primary-border': active, 'continue-step_disabled': disabled }]"
  >
    <div class="workflow-step__header">
      <p class="workflow-step__number primary-bg text">
        {{ `${index + 1}` }}
      </p>

      <div class="workflow-step__title">
        <span class="workflow-step__title-text">
          {{ stepInfo.title }}
        </span>
      </div>
    </div>

    <div class="workflow-step__content">
      <info-item :info="stepInfo.body" />
    </div>
  </div>
</template>

<script setup>
  import { computed } from 'vue';
  import InfoItem from '@/components/updateWorkflow/performAction/InfoItem.vue';
  import { getStepInfo } from '@/utils/workflows/setStepInfo';

  const props = defineProps({
    action: {
      type: Object,
      default: null,
    },
    index: {
      type: Number,
      default: null,
    },
    active: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  });

  const stepInfo = computed(() => getStepInfo(props.action.type, props.action));
</script>

<style lang="scss" scoped>
  @import '@/components/updateWorkflow/styles/workflowStep.scss';
  .continue-step {
    flex-basis: calc(50% - 15px);
    border: 2px solid transparent;
    padding: 0px 20px 16px 30px;
    display: block;
    height: 130px;

    &__fade {
      width: 100%;
      height: calc(100% - 65px);
      bottom: 0;
      left: 0;
      z-index: 2;
      position: absolute;
    }

    &_active {
      border: 2px solid $primary-cl;
    }

    &_disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
</style>
