<template>
  <div class="default-button-tooltip">
    <default-button
      :size="size"
      :color="color"
      :title-color="titleColor"
      :background-color="backgroundColor"
      :hovered-background-color="hoveredBackgroundColor"
      :disabled-background-color="disabledBackgroundColor"
      :noty-message-on-disabled="notyMessageOnDisabled"
      :icon-name="chosenButton.icon?.name"
      :is-loading="isLoading"
      :is-disabled="isDisabled"
      :title="chosenButton.name"
      :loading-title="chosenButton.loadingName"
      class="default-button-tooltip__button-left"
      form="square"
      @action="action"
    />
    <span class="default-button-tooltip__separate" />
    <default-tooltip
      data-closable="group"
      :items="buttonsGroupData"
      :selected-item="chosenButton"
      :hide-selected="true"
      list-position="right"
      :has-triangle="false"
      class="default-button-tooltip__button-right"
      :is-fulled="true"
      :is-disabled="isLoading"
      @select="buttonsGroupDataHandler"
    >
      <template #default>
        <default-button
          class="default-button-tooltip__toggler"
          icon-name="bxs-chevron-down"
          :is-disabled="isLoading"
          :size="size"
          :color="color"
          :title-color="titleColor"
          :background-color="backgroundColor"
          :hovered-background-color="hoveredBackgroundColor"
          :disabled-background-color="disabledBackgroundColor"
          :has-paddings="false"
          form="square"
        />
      </template>
    </default-tooltip>
  </div>
</template>

<script setup>
// DefaultButtonsSelect
  import { onMounted, ref } from 'vue';
  import DefaultButton from '@/components/base/uiKit/DefaultButton.vue';
  import DefaultTooltip from '@/components/base/uiKit.v3/DefaultTooltip.vue';

  const props = defineProps({
    buttonsGroupData: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: 's',
    },
    titleColor: {
      // для нетиповой стилизации
      type: String,
      default: '',
    },
    backgroundColor: {
      // для нетиповой стилизации
      type: String,
      default: '',
    },
    hoveredBackgroundColor: {
      // для нетиповой стилизации
      type: String,
      default: '',
    },
    disabledBackgroundColor: {
      // для нетиповой стилизации
      type: String,
      default: '',
    },
    notyMessageOnDisabled: {
      type: String,
      default: '',
    },
  });

  const emit = defineEmits(['action', 'select-option']);

  const chosenButton = ref({ name: '', loadingName: '' });

  onMounted(() => {
    chosenButton.value = props.buttonsGroupData[0] ?? chosenButton.value;
  });

  const buttonsGroupDataHandler = (value) => {
    chosenButton.value = value;
    emit('select-option', value.value);
  };

  const action = () => {
    emit('action', chosenButton.value);
  };
</script>

<style lang="scss">
.default-button-tooltip {
  position: relative;
  display: flex;

  &__button-left {
    margin-right: -1px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  &__button-right {
    margin-left: -1px;
  }

  &__separate {
    z-index: 120;
    position: relative;
    background: #fff;
    width: 1px;
  }

  &__toggler {
    border-bottom-left-radius: 0px;
    border-top-left-radius: 0px;
  }
}
</style>
