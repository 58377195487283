<!--
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
-->
<template>
  <div
    class="checkbox flex-row flex-gap-8"
    @click.stop="selectCheckbox"
  >
    <span
      class="checkbox__checkmark flex-row"
      :class="[
        'checkbox__checkmark_' + size,
        'checkbox__checkmark_' + appearance,
        { 'checkbox__checkmark_active': isActive || isSemiActive },
      ]"
    >
      <i
        v-if="isActive"
        class="bx bx-check"
      />

      <i
        v-else-if="isSemiActive"
        class="bx bx-minus"
      />
    </span>
    <slot />
  </div>
</template>

<script setup>
  const props = defineProps({
    isActive: {
      type: Boolean,
      default: false,
    },
    isSemiActive: {
      type: Boolean,
      default: false,
    },
    // m - 20px, s - 16px
    size: {
      type: String,
      default: 's',
    },
    // outline | fill
    appearance: {
      type: String,
      default: 'outline',
    },
  });

  const emit = defineEmits(['select-checkbox', 'update:isActive']);

  const selectCheckbox = () => {
    emit('select-checkbox', props.isActive);
    emit('update:isActive', !props.isActive);
  };
</script>

<style lang="scss" scoped>
.checkbox {
  cursor: pointer;

  &:hover {
    .checkbox__checkmark {
      &_fill:not(.checkbox__checkmark_active) {
        background-color: var(--neutral-200);
      }
    }
  }

  &__checkmark {
    width: 16px;
    height: 16px;
    border-radius: 4px;
    transition: .15s ease-out;
    flex-shrink: 0;

    &_s {
      width: 16px;
      height: 16px;
      border-radius: 4px;

      i {
        font-size: 16px;
        line-height: 16px;
      }
    }

    &_m {
      width: 20px;
      height: 20px;
      border-radius: 6px;

      i {
        font-size: 20px;
        line-height: 20px;
      }
    }

    &_fill {
      background-color: var(--neutral-100);
    }

    &_outline {
      box-shadow: inset 0 0 0 1px var(--neutral-300);
    }

    i {
      transition: opacity 0.15s ease-out;
      color: #fff;
      opacity: 0;
    }

    &_active {
      &.checkbox__checkmark_fill {
        background-color: var(--primary-cl);
      }

      &.checkbox__checkmark_outline {
        box-shadow: inset 0 0 0 1px var(--primary-cl);
        background-color: var(--primary-cl);
      }

      i {
        opacity: 1;
      }
    }
  }
}
</style>
