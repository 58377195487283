<template>
  <div
    ref="parent"
    class="flex-row flex-gap-4"
  >
    <default-label
      v-for="label in resultedLabels"
      :key="label._id"
      :label="label"
      :icon-name="label?.iconName"
      :size="size"
    />
    <p
      v-if="resultedLabels.length < filteredLabels.length"
      :class="[
        'text_' + size,
        'color_tertiary'
      ]"
    >
      {{ '+' + (filteredLabels.length - maxLabelsCount) }}
    </p>
  </div>
</template>
<script>
  import DefaultLabel from '@/components/base/uiKit/DefaultLabel';
  import { AiLabelsNames } from '@/data/ai/labels';

  export default {
    name: 'LabelsList',
    components: {
      DefaultLabel,
    },
    props: {
      labels: {
        type: Array,
        required: true,
      },
      size: {
        type: String,
        default: 'xs',
      },
      isNeedMaxLabels: {
        type: Boolean,
        default: true,
      },
    },
    data () {
      return {
        maxLabelsCount: 0,
      };
    },
    computed: {
      filteredLabels () {
        return this.labels.filter(label => label.type !== 'global');
      },
      resultedLabels () {
        let result = this.isNeedMaxLabels ? this.filteredLabels.slice(0, this.maxLabelsCount) : this.filteredLabels;
        result = result.map((label) => {
          switch (label.type) {
          case 'ai_generated':
            return {
              ...label,
              name: AiLabelsNames.value[label.name],
              iconName: 'bxs-magic-wand',
            };
          default:
            return label;
          }
        });
        return result;
      },
    },
    mounted () {
      this.setMaxLabelsCount();
    },
    methods: {
      setMaxLabelsCount () {
        // считает какое кличество лейблов может вместить родительский контейнер
        const parent = this.$refs.parent;
        const parentWidth = parent.offsetWidth;
        const childrenWidthToArray = Array.from(parent.childNodes).map(node => node.offsetWidth);
        let maxLabelsWidth = 16; // берется запас для конструкции +N_LABELS равный 16px
        let total = 0;
        for (let i = 0; i < childrenWidthToArray.length; i++) {
          maxLabelsWidth += childrenWidthToArray[i] + 4; // + margin
          if (maxLabelsWidth > parentWidth) break;
          total++;
        }
        this.maxLabelsCount = total || 1;
      },
    },
  };
</script>
