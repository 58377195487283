/*
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
*/
import { useHttp } from '@/middleware/httpMiddleware';

async function getLabels (types = ['profile']) {
  const data = await useHttp(
    '/labels/fetch',
    'GET',
    null,
    {
      labels_types: JSON.stringify(types),
    }
  );
  return data.payload;
}
async function createLabel (name, color, type = 'profile') {
  const data = await useHttp(
    '/labels/create',
    'POST',
    {
      name,
      color,
      label_type: type,
    }
  );
  return data.payload;
}

async function deleteLabel (id) {
  const data = await useHttp(
    `/labels/${id}/delete`,
    'POST',
    null
  );
  return data.payload;
}
export { getLabels, createLabel, deleteLabel };
