<template>
  <div
    ref="vldParent"
    class="workspace-auto-labels vld-parent"
  >
    <settings-switch-block
      title="AI-powered sentiment analysis"
      description="Automated prospect labeling based on sentiment of recent messages for streamlined lead management"
      class="mt-2"
      :is-checked="isAiLabeling"
      @change="handleSwitcher"
    />

    <ul
      class="workspace-auto-labels__list"
      :class="{'workspace-auto-labels__list_disabled' : !isAiLabeling }"
    >
      <preference-label-item
        v-for="label in autoLabels"
        :key="label._id"
        :label-name="label.name"
        :label-type="label.type"
        :label-color="label.color"
      />
    </ul>
  </div>
</template>

<script setup>
  import { ref, computed } from 'vue';
  import PreferenceLabelItem from '@/components/preferencesPage/labels/PreferenceLabelItem.vue';
  import { loadingWrapper, useLoader } from '@/use/loader';
  import SettingsSwitchBlock from '@/components/SettingsPage/SettingsSwitchBlock.vue';
  import { updateSpace } from '@/api/spaceMethods';
  import { useSnack } from '@/lib/useSnack';
  import { useStore } from '@/store';
  import { useI18n } from '@/i18n';
  import { useUpgradeModal } from '@/use/useUpgradeModal';
  import { AiLabelsEnum, AiLabelsNames, AiLabelsColors } from '@/data/ai/labels';

  const { t } = useI18n();
  const store = useStore();
  const { $snack } = useSnack();
  const space = computed(() => store.getters['account/account']);

  const autoLabels = Object.values(AiLabelsEnum).map((value) => ({
    _id: value,
    name: AiLabelsNames.value[value],
    color: AiLabelsColors[value],
    type: 'auto',
  }));

  const isLoading = ref(false);
  const { vldParent, loaderWatcher } = useLoader();
  loaderWatcher(isLoading);

  const isAiLabeling = ref(space.value.is_ai_labeling);

  const handleSwitcher = () => {
    useUpgradeModal(() => {
      isAiLabeling.value = !isAiLabeling.value;
      updateCustomerHandler({ is_ai_labeling: isAiLabeling.value });
    }, 'ai_powered_labels');
  };

  const updateCustomerHandler = loadingWrapper(async (update) => {
    const updatedSpace = await updateSpace(space.value._id, update);
    store.dispatch('account/SET_ACCOUNT', updatedSpace);
    $snack.success(t('adminPage.customers.info.notifications.customerUpdated'));
  }, isLoading);
</script>

<style lang="scss">
.workspace-auto-labels {
  &__list {
    margin-top: 8px;
    display: grid;
    gap: 4px;

    &_disabled {
      opacity: 0.5;
    }
  }
}
</style>
