<!--
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
-->
<template>
  <aside class="new-page-sidebar">
    <slot name="header" />
    <ul>
      <template v-for="item in list">
        <router-link
          v-if="item.isVisible !== false"
          :key="item.path"
          class="new-page-sidebar__link"
          :to="item.path"
        >
          <default-list-item
            :title="item.title"
            :left-icon-name="item.icon"
            :is-hovered-list="true"
            :class="itemClasses"
            :is-activated-list="isActiveListItem(item.path)"
            :label="item.label"
          />
        </router-link>
      </template>
    </ul>
  </aside>
</template>

<script>
  import DefaultListItem from '@/components/base/uiKit/DefaultListItem.vue';
  import { tierFeatures } from '@/data/tiersFeatures';
  export default {
    name: 'PageSliderNew',
    components: {
      DefaultListItem,
    },
    props: {
      list: {
        type: Array,
        required: true,
      },
      itemClasses: {
        type: String,
        default: '',
      },
    },
    data () {
      return {
        tierFeatures,
      };
    },
    computed: {
      isActiveListItem () {
        return path => this.$route.path.includes(path);
      },
    },
  };
</script>
<style lang="scss">
.new-page-sidebar {
  width: 100%;
  border-radius: 8px;
  background: var(--background-color);
  &__link{
    display: contents;
  }
}
</style>
