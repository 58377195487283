import i18n from '@/i18n';

const months = [
  i18n.t('date.month.january'),
  i18n.t('date.month.february'),
  i18n.t('date.month.march'),
  i18n.t('date.month.april'),
  i18n.t('date.month.may'),
  i18n.t('date.month.june'),
  i18n.t('date.month.july'),
  i18n.t('date.month.august'),
  i18n.t('date.month.september'),
  i18n.t('date.month.october'),
  i18n.t('date.month.november'),
  i18n.t('date.month.december'),
];

const days = [
  i18n.t('date.day.sunday'),
  i18n.t('date.day.monday'),
  i18n.t('date.day.tuesday'),
  i18n.t('date.day.wednesday'),
  i18n.t('date.day.thursday'),
  i18n.t('date.day.friday'),
  i18n.t('date.day.saturday'),
];

export const generateDatePickerLocale = () => {
  return {
    formatLocale: {
      months: months.map(month => month.full),
      monthsShort: months.map(month => month.short),
      weekdays: days.map(day => day.full),
      weekdaysShort: days.map(day => day.short),
      weekdaysMin: days.map(day => day.min),
      firstDayOfWeek: 1,
      firstWeekContainsDate: 1,
    },
  };
};

export const generateChartLocale = () => {
  const toolbar = {
    menu: i18n.t('charts.toolbar.menu'),
    selection: i18n.t('charts.toolbar.selection'),
    selectionZoom: i18n.t('charts.toolbar.selectionZoom'),
    zoomIn: i18n.t('charts.toolbar.zoomIn'),
    zoomOut: i18n.t('charts.toolbar.zoomOut'),
    reset: i18n.t('charts.toolbar.reset'),
    exportToSVG: i18n.t('charts.toolbar.exportToSVG'),
    exportToPNG: i18n.t('charts.toolbar.exportToPNG'),
    exportToCSV: i18n.t('charts.toolbar.exportToCSV'),
  };

  return {
    name: i18n.locale,
    options: {
      months: months.map(month => month.full),
      shortMonths: months.map(month => month.short),
      toolbar,
    },
  };
};

export const generateEmojiPickerLocale = () => {
  return ({
    search: i18n.t('emojiPicker.search'),
    notfound: i18n.t('emojiPicker.notfound'),
    categories: {
      search: i18n.t('emojiPicker.categories.search'),
      recent: i18n.t('emojiPicker.categories.recent'),
      smileys: i18n.t('emojiPicker.categories.smileys'),
      people: i18n.t('emojiPicker.categories.people'),
      nature: i18n.t('emojiPicker.categories.nature'),
      foods: i18n.t('emojiPicker.categories.foods'),
      activity: i18n.t('emojiPicker.categories.activity'),
      places: i18n.t('emojiPicker.categories.places'),
      objects: i18n.t('emojiPicker.categories.objects'),
      symbols: i18n.t('emojiPicker.categories.symbols'),
      flags: i18n.t('emojiPicker.categories.flags'),
      custom: i18n.t('emojiPicker.categories.custom'),
    },
  });
};

export const getStatusTranslation = (status) => {
  const key = `status.${status}`;
  return i18n.te(key) ? i18n.t(key) : status;
};
