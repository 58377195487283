import { useHttp } from '@/middleware/httpMiddleware';

async function activateDialer () {
  const data = await useHttp(
    '/dialer/activate',
    'POST'
  );

  return data.payload;
}

async function getDialerToken (key) {
  const data = await useHttp(
    '/dialer/token',
    'GET',
    null,
    {
      one_time_key: key,
    }
  );

  return data.payload;
}

async function getPhones () {
  const data = await useHttp(
    '/dialer/phones',
    'GET'
  );

  return data.payload;
}

async function getCallHistory (phoneId, skip = 0, limit = 200) {
  const data = await useHttp(
    `/dialer/phones/${phoneId}/calls`,
    'GET',
    null,
    {
      skip,
      limit,
    }
  );

  return data.payload;
}

async function validateCall (phone) {
  const data = await useHttp(
    '/dialer/validate',
    'POST',
    null,
    {
      phone_number: phone,
    }
  );

  return data.payload;
}

async function getCountries () {
  const data = await useHttp(
    '/dialer/countries',
    'GET',
    null
  );

  return data.payload;
}

async function getStates (countryCode, category) {
  const data = await useHttp(
    '/dialer/states',
    'GET',
    null,
    {
      country_code: countryCode,
      category,
    }
  );

  return data.payload;
}

async function getRegions (countryCode, category, state = '') {
  const data = await useHttp(
    '/dialer/regions',
    'GET',
    null,
    {
      country_code: countryCode,
      category,
      state_id: state,
    }
  );

  return data.payload;
}

async function buyPhones (regionId, numberAmount, numberToRedirect = '') {
  const data = await useHttp(
    '/dialer/phones/buy',
    'POST',
    {
      region_id: regionId,
      count: numberAmount,
      number_to_redirect: numberToRedirect,
    },
    null,
    true
  );

  return data.payload;
}

async function updatePhone (phoneId, update) {
  const data = await useHttp(
    `/dialer/phones/${phoneId}`,
    'PUT',
    update,
    null,
    true
  );

  return data.payload;
}

async function deletePhone (phoneId) {
  const data = await useHttp(
    `/dialer/phones/${phoneId}`,
    'DELETE'
  );

  return data.payload;
}

export {
  activateDialer,
  getDialerToken,
  getPhones,
  getCallHistory,
  validateCall,
  getCountries,
  getStates,
  getRegions,
  buyPhones,
  updatePhone,
  deletePhone
};
