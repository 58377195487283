/*
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
*/
import { getLabels } from '@/api/labelsMethods';

const state = () => ({
  labelsList: null,
  aiLabelsList: null,
  spaceLabelsList: null,
  templateLabelsList: null,
});

const getters = {
  labelsList: state => {
    return state.labelsList;
  },
  aiLabelsList: state => {
    return state.aiLabelsList;
  },
  spaceLabelsList: state => {
    return state.spaceLabelsList;
  },
  templateLabelsList: state => {
    return state.templateLabelsList;
  },
};

const mutations = {
  SET_LABELS_LIST (state, payload) {
    state.labelsList = payload;
  },
  SET_AI_LABELS_LIST (state, payload) {
    state.aiLabelsList = payload;
  },
  SET_SPACE_LABELS_LIST (state, payload) {
    state.spaceLabelsList = payload;
  },
  SET_TEMPLATE_LABELS_LIST (state, payload) {
    state.templateLabelsList = payload;
  },
};

const actions = {
  async SET_LABELS_LISTS ({ commit }) {
    const labelsResponse = await getLabels(['profile', 'ai_generated', 'space', 'template']);

    const labels = { profile: [], space: [], template: [], ai_generated: [] };

    labelsResponse.forEach((label) => {
      if (!labels?.[label.type]) return;
      labels[label.type].push(label);
    });

    commit('SET_LABELS_LIST', labels.profile);
    commit('SET_AI_LABELS_LIST', labels.ai_generated);
    commit('SET_SPACE_LABELS_LIST', labels.space);
    commit('SET_TEMPLATE_LABELS_LIST', labels.template);

    return labels;
  },
  async SET_LABELS_LIST ({ commit }, payload) {
    const labels = payload || await getLabels();
    commit('SET_LABELS_LIST', labels);
    return labels;
  },
  async SET_SPACE_LABELS_LIST ({ commit }, payload) {
    const labels = payload || await getLabels(['space']);
    commit('SET_SPACE_LABELS_LIST', labels);
    return labels;
  },
  async SET_TEMPLATE_LABELS_LIST ({ commit }, payload) {
    const labels = payload || await getLabels(['template']);
    commit('SET_TEMPLATE_LABELS_LIST', labels);
    return labels;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
