import { TooltipAppearance, TooltipPosition } from '@/plugins/tooltip/enums';
const BASE_CLASS = 'v-tooltip_';
export function setTooltipPosition(targetElement, tooltip, position) {
    const elementRect = targetElement.getBoundingClientRect();
    const tooltipShift = _calculateShift(elementRect, position);
    const tooltipPosition = _calculateTooltipPosition(elementRect, tooltipShift);
    tooltip.style.setProperty('--root-top', tooltipPosition.top + 'px');
    tooltip.style.setProperty('--root-left', tooltipPosition.left + 'px');
}
export function getWidthValue(width) {
    if (typeof width === 'number') {
        return width + 'px';
    }
    return width;
}
export function getAppearanceClass(appearance) {
    switch (appearance) {
        case TooltipAppearance.LIGHT:
            return BASE_CLASS + TooltipAppearance.LIGHT;
        default:
            return BASE_CLASS + TooltipAppearance.DARK;
    }
}
export function getPositionClass(position) {
    switch (position) {
        case TooltipPosition.TOP:
            return [BASE_CLASS + TooltipPosition.TOP];
        case TooltipPosition.TOP_LEFT:
            return [BASE_CLASS + TooltipPosition.TOP, BASE_CLASS + TooltipPosition.TOP_LEFT];
        case TooltipPosition.TOP_RIGHT:
            return [BASE_CLASS + TooltipPosition.TOP, BASE_CLASS + TooltipPosition.TOP_RIGHT];
        case TooltipPosition.BOTTOM:
            return [BASE_CLASS + TooltipPosition.BOTTOM];
        case TooltipPosition.BOTTOM_LEFT:
            return [BASE_CLASS + TooltipPosition.BOTTOM, BASE_CLASS + TooltipPosition.BOTTOM_LEFT];
        case TooltipPosition.BOTTOM_RIGHT:
            return [BASE_CLASS + TooltipPosition.BOTTOM, BASE_CLASS + TooltipPosition.BOTTOM_RIGHT];
        case TooltipPosition.LEFT:
            return [BASE_CLASS + TooltipPosition.LEFT];
        case TooltipPosition.LEFT_TOP:
            return [BASE_CLASS + TooltipPosition.LEFT, BASE_CLASS + TooltipPosition.LEFT_TOP];
        case TooltipPosition.LEFT_BOTTOM:
            return [BASE_CLASS + TooltipPosition.LEFT, BASE_CLASS + TooltipPosition.LEFT_BOTTOM];
        case TooltipPosition.RIGHT:
            return [BASE_CLASS + TooltipPosition.RIGHT];
        case TooltipPosition.RIGHT_TOP:
            return [BASE_CLASS + TooltipPosition.RIGHT, BASE_CLASS + TooltipPosition.RIGHT_TOP];
        case TooltipPosition.RIGHT_BOTTOM:
            return [BASE_CLASS + TooltipPosition.RIGHT, BASE_CLASS + TooltipPosition.RIGHT_BOTTOM];
        default:
            return [BASE_CLASS + TooltipPosition.TOP];
    }
}
// #region Helpers
function _calculateShift(clientRect, position) {
    const { width, height } = clientRect;
    switch (position) {
        case TooltipPosition.BOTTOM:
        case TooltipPosition.BOTTOM_LEFT:
        case TooltipPosition.BOTTOM_RIGHT:
            return {
                top: height,
                left: width / 2,
            };
        case TooltipPosition.LEFT:
        case TooltipPosition.LEFT_TOP:
        case TooltipPosition.LEFT_BOTTOM:
            return {
                top: height / 2,
                left: 0,
            };
        case TooltipPosition.RIGHT:
        case TooltipPosition.RIGHT_TOP:
        case TooltipPosition.RIGHT_BOTTOM:
            return {
                top: height / 2,
                left: width,
            };
        default:
            return {
                top: 0,
                left: width / 2,
            };
    }
}
function _calculateTooltipPosition(clientRect, shift) {
    return {
        top: clientRect.top + shift.top,
        left: clientRect.left + shift.left,
    };
}
// #endregion
