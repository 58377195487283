import i18n from '@/i18n';

export const getPerformActionName = (action) => {
  switch (action) {
    case 'li_view_profile': {
      return i18n.t('campaignsPage.editor.steps.performAction.switchers.viewProfile');
    }
    case 'li_endorse_skills': {
      return i18n.t('campaignsPage.editor.steps.performAction.switchers.endorseTopSkills');
    }
    case 'li_follow_profile': {
      return i18n.t('campaignsPage.editor.steps.performAction.switchers.followProfile');
    }
    case 'li_fetch_posts': {
      return i18n.t('campaignsPage.editor.steps.performAction.switchers.likeLastPosts');
    }
    default: {
      return 'New action';
    }
  }
};
