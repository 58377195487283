import { render, staticRenderFns } from "./CrmChosenItem.vue?vue&type=template&id=56a32887"
import script from "./CrmChosenItem.vue?vue&type=script&lang=js"
export * from "./CrmChosenItem.vue?vue&type=script&lang=js"
import style0 from "./CrmChosenItem.vue?vue&type=style&index=0&id=56a32887&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../temp_divo-ui/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports