<!--
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
-->
<template>
  <portal to="body-end">
    <modal
      :key="key"
      width="500px"
      height="auto"
      :name="name"
      @closed="handleClose"
    >
      <div class="push-crm">
        <div class="push-crm__header">
          <h2 class="push-crm__title">
            {{ title ? title : 'Push to CRM' }}
          </h2>
          <button class="push-crm__x">
            <i
              class="bx bx-x"
              @click="handleClose"
            />
          </button>
        </div>
        <div class="push-crm__body">
          <div
            v-if="isMissing"
            class="push-crm__missing"
          >
            <p class="push-crm__des">
              You don't have integration with {{ integrationName }}
            </p>
            <div class="push-crm__buttons">
              <default-button
                form="rectangle"
                :size="'m'"
                title="Cancel"
                color="neutral"
                @click.native="handleClose"
              />
              <default-button
                form="rectangle"
                :size="'m'"
                title="Go to Integration"
                color="primary"
                @click.native="connect"
              />
            </div>
          </div>
          <div
            v-else-if="!isMissing && numberPushToCrm"
            class="push-crm__stock"
          >
            <p
              v-if="progressLoad >= numberPushToCrm"
              class="push-crm__des"
            >
              <span class="push-crm__des-icon"><i class="bx bx-check" /></span>
              <span v-if="description">{{ description }}</span>
              <span v-else>{{ numberPushToCrm }} {{ numberPushToCrm > 1 ? `prospects have been successfully added to the ${integrationName} CRM` : `prospect has been successfully added to the ${ integrationName } CRM` }}</span>
            </p>
            <p
              v-if="progressLoad < numberPushToCrm"
              class="push-crm__des"
            >
              <span> Addition prospects ({{ progressLoad }}/{{ numberPushToCrm }})</span>
            </p>
            <amazing-progress-bar
              :tip="false"
              :percent="progressLoad / numberPushToCrm"
              class="push-crm__diagram"
            />
            <div
              v-if="progressLoad >= numberPushToCrm"
              class="mt-1"
            >
              <p class="text_xs color_tertiary">
                <i class="bx bx-info-circle color-tertiary" />
                <span>
                  If duplicates are present, you'll find them under the "Duplicates" tab in the Prospects section. It might take a moment for them to show up, so consider refreshing the page.
                </span>
              </p>
            </div>
            <div
              v-if="progressLoad >= numberPushToCrm"
              class="push-crm__buttons"
            >
              <default-button
                form="rectangle"
                :size="'m'"
                title="Great!"
                color="primary"
                @click.native="handleClose"
              />
            </div>
          </div>
          <div
            v-else
            class="push-crm__stock"
          >
            <p class="push-crm__des">
              Select profiles
            </p>
            <div class="push-crm__buttons">
              <default-button
                form="rectangle"
                :size="'m'"
                title="Okay!"
                color="primary"
                @click.native="handleClose"
              />
            </div>
          </div>
        </div>
      </div>
    </modal>
  </portal>
</template>

<script>
  import AmazingProgressBar from '@/components/base/AmazingProgressBar.vue';
  import DefaultButton from '@/components/base/uiKit/DefaultButton.vue';
  import { useCrmDedupe } from '@/components/profilesPage/use/useCrmDedupe';
  import { connectCRM } from '@/api/crmIntegration';

  export default {
    name: 'PushCRMModal',
    components: {
      DefaultButton,
      AmazingProgressBar,
    },
    props: {
      name: {
        type: String,
        default: 'PushCRMModal',
      },
      title: {
        type: String,
        default: 'Pushing profiles to CRM',
      },
      description: {
        type: String,
        default: 'Successfully pushed',
      },
      show: {
        type: Boolean,
        default: false,
      },
      integrationName: {
        type: String,
        default: 'Integration',
      },
      numberPushToCrm: {
        type: Number,
        default: 0,
      },
      isMissing: {
        type: Boolean,
        default: false,
      },
    },
    setup () {
      const { fetchCrmDedupeTotalRecords } = useCrmDedupe();
      return { fetchCrmDedupeTotalRecords };
    },
    data () {
      return {
        key: this.name,
        progressLoad: -1,
      };
    },
    watch: {
      async show (show) {
        show
          ? this.$modal.show(this.name)
          : this.$modal.hide(this.name);
        if (!show) {
          this.key = `${this.key} ${new Date().getTime()}`;
        } else {
          if (!this.isMissing && this.numberPushToCrm) {
            this.progressLoad = 0;
            this.startTimer(70);
          }
        }
      },

      async progressLoad (time) {
        if (time >= this.numberPushToCrm) {
          this.stopTimer();
          await this.fetchCrmDedupeTotalRecords();
        }
      },
    },
    destroyed () {
      this.stopTimer();
    },
    methods: {
      startTimer (milliseconds) {
        this.timer = setInterval(() => {
          this.progressLoad++;
        }, milliseconds);
      },
      stopTimer () {
        clearTimeout(this.timer);
        // if (this.progressLoad >= this.numberPushToCrm) {
        //   this.$noty.success('Push to SRM');
        // }
      },
      handleClose () {
        this.$emit('update:show', false);
      },
      async connect () {
        try {
          const payload = await connectCRM(this.integrationName.toLowerCase());
          if (payload.url) {
            if (payload.url.match(/default/)) {
              await this.defaultAuthorize(payload.url);
            } else window.open(payload.url, '_blank');
          }
        } catch (e) {
          this.$noty.error(e.message);
        }
      },
    },
  };
</script>

<style lang="scss">
.push-crm {
  position: relative;
  padding: 24px;
  background: var(--background-color);
  border-radius: 8px;

  &__diagram {
    margin-top: 8px;
  }

  &__x {
    cursor: pointer;
    position: absolute;
    top: 16px;
    right: 16px;
    background: none;
    border: none;
    font-size: 20px;
  }

  &__des {
    display: flex;
    margin-top: 16px;
    font-size: 14px;
  }

  &__des-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #56C12C;
    min-width: 16px;
    height: 16px;
    margin-right: 8px;
    border-radius: 50%;

    i {
      color: white;
    }
  }

  &__buttons {
    margin-top: 16px;
    display: flex;
    justify-content: flex-end;
    gap: 4px;
  }
}
</style>
