<template>
  <default-tooltip
    class="text_xs"
    :items="tooltipOptions"
    :max-height="maxHeight"
    :is-disabled="!isEditable"
    @select="updateSelectedOption"
  >
    <slot />
  </default-tooltip>
</template>

<script setup>
  import DefaultTooltip from '@/components/base/uiKit.v3/DefaultTooltip.vue';

  defineProps({
    tooltipOptions: {
      type: Array,
      default: () => [],
    },
    isEditable: {
      type: Boolean,
      default: false,
    },
    maxHeight: {
      type: String,
      default: '',
    },
  });

  const emit = defineEmits(['update:selected-option']);

  const updateSelectedOption = (option) => {
    emit('update:selected-option', option);
  };
</script>

<style lang="scss">
.task-card-info {
  &__date-picker {
    width: auto;

    .mx-icon-calendar,
    .mx-icon-clear {
      display: none;
    }
  }
}
</style>
