import { getPhones } from '@/api/voxMethods';

const state = () => ({
  numbersList: null,
  isSupported: 'hui',
});

const getters = {
  isSupported: state => {
    return state.isSupported;
  },
  numbersList: state => {
    return state.numbersList;
  },
};

const mutations = {
  SET_IS_SUPPORTED (state, payload) {
    state.isSupported = payload;
  },
  SET_NUMBERS_LIST (state, payload) {
    state.numbersList = payload;
  },
  UPDATE_NUMBERS_LIST (state, payload) {
    const numbersList = state.numbersList?.slice(0) || [];
    if (!numbersList.length) {
      state.numbersList = payload;
    }

    const existingIndex = numbersList.findIndex(number => number.id === payload.id);

    if (existingIndex > -1) {
      numbersList.splice(existingIndex, 1, payload);
    } else {
      numbersList.push(payload);
    }
    state.numbersList = numbersList;
  },
  ADD_NUMBERS (state, payload) {
    const numbersList = state.numbersList?.slice(0) || [];

    payload.data.forEach(num => {
      if (num.id) {
        numbersList.push(num);
      }
    });
    state.numbersList = numbersList;
  },
  DELETE_NUMBER (state, payload) {
    const numbersList = state.numbersList?.slice(0) || [];
    state.numbersList = numbersList.filter(number => number.id !== payload.id);
  },
};

const actions = {
  SET_IS_SUPPORTED ({ commit }, payload) {
    commit('SET_IS_SUPPORTED', payload);
  },
  async SET_NUMBERS_LIST ({ commit }, payload) {
    const numbers = payload || await getPhones();
    commit('SET_NUMBERS_LIST', numbers?.data || []);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
