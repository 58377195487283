import { reactive, computed, set } from 'vue';
import { useStore } from '@/store';
import { fetchResolveRequests, closeResolveRequests } from '@/api/integrations/crmDeduplicationMethods';

const store = useStore();
const selectedCredential = computed(() => store.getters['credentials/selectedCredential']);
const selectedCredentialId = computed(() => selectedCredential.value?._id || '');

const crmDedupeState = reactive({
  totalResolveRequests: 0,
  resolveRequests: [],
  resultedProfiles: {},
  pagination: {
    offset: 0,
    limit: 25,
  },
});

export const useCrmDedupe = (platform) => {
  const fetchCrmDedupeTotalRecords = async () => {
    const response = await fetchResolveRequests(
      selectedCredentialId.value,
      'profile',
      0,
      1,
      platform
    );

    crmDedupeState.totalResolveRequests = response.total_records;
  };

  const fetchCrmDedupeResolveRequests = async () => {
    crmDedupeState.pagination.offset = 0;

    const response = await fetchResolveRequests(
      selectedCredentialId.value,
      'profile',
      0,
      crmDedupeState.pagination.limit,
      platform
    );

    crmDedupeState.resolveRequests = response.crm_resolve_requests;
    crmDedupeState.totalResolveRequests = response.total_records;
  };

  const fetchMoreCrmDedupeResolveRequests = async () => {
    crmDedupeState.pagination.offset += crmDedupeState.pagination.limit;

    const response = await fetchResolveRequests(
      selectedCredentialId.value,
      'profile',
      crmDedupeState.pagination.offset,
      crmDedupeState.pagination.limit,
      platform
    );

    crmDedupeState.resolveRequests.push(...response.crm_resolve_requests);
    crmDedupeState.totalResolveRequests = response.total_records;

    return response.crm_resolve_requests;
  };

  const resolveAllCrmDedupeRequests = async (decision) => {
    const resultedProfilesEntries = Object.entries(crmDedupeState.resultedProfiles);

    const resultedProfiles = resultedProfilesEntries.reduce((acc, [id, data]) => {
      return Object.assign(acc, {
        [id]: {
          decision,
          data,
        },
      });
    }, {});

    await closeResolveRequests(selectedCredentialId, resultedProfiles, platform);

    resetStateToDefaults();
  };

  const initResultedProfile = (requestId, profile) => {
    set(crmDedupeState.resultedProfiles, requestId, {});
    Object.assign(crmDedupeState.resultedProfiles[requestId], profile);
  };

  const resetStateToDefaults = () => {
    crmDedupeState.totalResolveRequests = 0;
    crmDedupeState.resolveRequests = [];
    crmDedupeState.resultedProfiles = {};
    crmDedupeState.pagination.offset = 0;
  };

  return {
    crmDedupeState,
    fetchCrmDedupeTotalRecords,
    fetchCrmDedupeResolveRequests,
    fetchMoreCrmDedupeResolveRequests,
    resolveAllCrmDedupeRequests,
    initResultedProfile,
  };
};
